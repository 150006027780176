import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import Loading from "../../../../components/Loading";
import { useState } from "react";

const Form4 = ({ formik }) => {
  const [loading, setLoading] = useState(false);

  const hundleCEP = () => {
    setLoading(true);
    if (!formik.touched.cep) formik.setFieldTouched("cep", true);

    try {
      if (!formik.errors.cep) {
        const cep = formik.values.cep.replace(/\D/g, "");
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((resp) => resp.text())
          .then((text) => JSON.parse(text))
          .then((data) => {
            formik.setValues({
              ...formik.values,
              cep: formik.values.cep,
              endereco: data.logradouro,
              bairro: data.bairro,
              uf: data.uf,
              municipio: data.localidade,
              tipo_logradouro: data.logradouro.split(" ")[0],
            });
          });
      }
    } catch {
      formik.setFieldError("cep", "CEP inválido");
    }
    setLoading(false);
  };

  console.log(formik.values.cep);

  if (loading) return <Loading />;
  else
    return (
      <>
        <Form.Group
          className="w-100 m-0 align-content-center mb-4"
          controlId={"CEP"}
        >
          <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
            CEP
          </Form.Label>

          <Form.Control
            as={IMaskInput}
            mask={"00000-000"}
            className="rounded-4"
            name="cep"
            type="text"
            onChange={formik.handleChange}
            onBlur={hundleCEP}
            value={formik.values.cep}
            isInvalid={formik.touched.cep && formik.errors.cep}
          />
        </Form.Group>

        <Form.Group
          className="w-100 m-0 align-content-center mb-4"
          controlId={"endereco"}
        >
          <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
            Endereço
          </Form.Label>

          <Form.Control
            className="rounded-4"
            name="endereco"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endereco}
            isInvalid={formik.touched.endereco && formik.errors.endereco}
          />
        </Form.Group>

        <Row>
          <Col md>
            <Form.Group
              className="w-100 m-0 align-content-center mb-4"
              controlId={"tipo_logradouro"}
            >
              <Form.Label
                className=" mb-0 fw-bold"
                style={{ fontSize: "1rem" }}
              >
                Tipo de Logradouro
              </Form.Label>

              <Form.Control
                className="rounded-4"
                name="tipo_logradouro"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tipo_logradouro}
                isInvalid={
                  formik.touched.tipo_logradouro &&
                  formik.errors.tipo_logradouro
                }
              />
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group
              className="w-100 m-0 align-content-center mb-4"
              controlId={"numero"}
            >
              <Form.Label
                className=" mb-0 fw-bold"
                style={{ fontSize: "1rem" }}
              >
                Número
              </Form.Label>

              <Form.Control
                className="rounded-4"
                name="numero"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.numero}
                isInvalid={formik.touched.numero && formik.errors.numero}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group
          className="w-100 m-0 align-content-center mb-4"
          controlId={"complemento"}
        >
          <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
            Complemento
          </Form.Label>

          <Form.Control
            className="rounded-4"
            name="complemento"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.complemento ? formik.values.complemento : ""}
            isInvalid={formik.touched.complemento && formik.errors.complemento}
          />
          {formik.touched.complemento && formik.errors.complemento ? (
            <div className="text-danger fw-bold">
              {formik.errors.complemento}
            </div>
          ) : null}
        </Form.Group>

        <Form.Group
          className="w-100 m-0 align-content-center mb-4"
          controlId={"bairro"}
        >
          <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
            Bairro
          </Form.Label>

          <Form.Control
            className="rounded-4"
            name="bairro"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bairro}
            isInvalid={formik.touched.bairro && formik.errors.bairro}
          />
        </Form.Group>

        <Row>
          <Col md>
            <Form.Group
              className="w-100 m-0 align-content-center mb-4"
              controlId={"uf"}
            >
              <Form.Label
                className=" mb-0 fw-bold"
                style={{ fontSize: "1rem" }}
              >
                Unidade Federativa
              </Form.Label>

              <Form.Control
                as={IMaskInput}
                mask={"aa"}
                className="rounded-4"
                name="uf"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.uf}
                isInvalid={formik.touched.uf && formik.errors.uf}
              />
            </Form.Group>
          </Col>
          <Col md>
            <Form.Group
              className="w-100 m-0 align-content-center mb-4"
              controlId={"municipio"}
            >
              <Form.Label
                className=" mb-0 fw-bold"
                style={{ fontSize: "1rem" }}
              >
                Município
              </Form.Label>

              <Form.Control
                className="rounded-4"
                name="municipio"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.municipio}
                isInvalid={formik.touched.municipio && formik.errors.municipio}
              />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
};

export default Form4;
