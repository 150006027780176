import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import Menu from "../../../../components/Menu";
import Close from "../../../../components/Close";
import Steps from "../Steps";
import { useContext, useEffect } from "react";
import { RegisterContext } from "../../../../context/RegisterContext";
import Swal from "sweetalert2";
import Auth from "../../../../helpers/Auth";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/Loading";

const Form1 = () => {
  const {
    dados_usuarios,
    setDados_usuarios,
    loading,
    setLoading,
    setDatabase,
    db,
  } = useContext(RegisterContext);
  const navigate = useNavigate();
  const { http, logout } = Auth();

  function handleSubmit(e) {
    e.preventDefault();
    if (
      new Date().getTime() <= new Date(dados_usuarios.data_nascimento).getTime()
    ) {
      return messageError("Selecione uma data de nascimento válida!");
    }
    navigate("/register/form2");
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setDados_usuarios({ ...dados_usuarios, [name]: value });
  }

  function messageError(message) {
    Swal.fire({
      icon: "error",
      text: message,
      confirmButtonColor: "#dc3545",
    });
  }

  useEffect(() => {
    (async () => {
      const response = await http.get("/pegar-dados-uf").catch((error) => {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      });
      setDatabase(response.data);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <Menu />
      <Container className="p-3 justify-content-center align-items-center d-flex">
        <Card
          className="border-0 shadow-sm rounded-4 p-4"
          style={{
            backgroundColor: "#fff",
            maxWidth: "510px",
            minWidth: "380px",
          }}
        >
          <Form
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
              }
            }}
            className="h-100"
            onSubmit={handleSubmit}
          >
            <Close />

            <Steps page={1} tamanho={6} />

            <div className="p-2">
              <Row>
                <Form.Group
                  className="w-100 m-0 align-content-center mb-4"
                  controlId={"nome"}
                >
                  <Form.Label
                    className=" mb-0 fw-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    Nome Completo / Nome Social{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="rounded-4"
                    name="nome_completo"
                    type="text"
                    onChange={handleChange}
                    value={dados_usuarios.nome_completo}
                    required
                  />
                </Form.Group>
              </Row>

              <Row>
                <Col sm>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"nascimento"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Data de Nascimento <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="rounded-4"
                      name="data_nascimento"
                      type="date"
                      onChange={handleChange}
                      value={dados_usuarios.data_nascimento}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"sexo"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Sexo <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-4"
                      name="sexo"
                      type="text"
                      onChange={handleChange}
                      defaultValue={dados_usuarios.sexo || "default"}
                      required
                    >
                      <option value="default" disabled>
                        Selecione uma opção
                      </option>
                      {db.sexo.map((item, index) => {
                        return (
                          <option key={index} value={item.generos}>
                            {item.generos}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Form.Group
                  className="w-100 m-0 align-content-center mb-4"
                  controlId={"cor-raca"}
                >
                  <Form.Label
                    className=" mb-0 fw-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    Cor / Raça <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="rounded-4"
                    name="cor_raca"
                    type="text"
                    onChange={handleChange}
                    defaultValue={dados_usuarios.cor_raca || "default"}
                    required
                  >
                    <option value="default" disabled>
                      Selecione uma opção
                    </option>
                    {db.etnia.map((item, index) => {
                      return (
                        <option key={index} value={item.nome}>
                          {item.nome}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"telefone1"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Tel. Celular <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="rounded-4"
                      as={IMaskInput}
                      mask={"(00)00000-0000"}
                      name="Telefone1"
                      type="text"
                      value={dados_usuarios.Telefone1}
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"telefone2"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Tel. Residencial
                    </Form.Label>
                    <Form.Control
                      className="rounded-4"
                      as={IMaskInput}
                      mask={"(00)0000-0000"}
                      name="Telefone2"
                      type="text"
                      value={dados_usuarios.Telefone2}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"estado-civil"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Estado Civil <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-4"
                      name="estado_civil"
                      type="text"
                      onChange={handleChange}
                      defaultValue={dados_usuarios.estado_civil || "default"}
                      required
                    >
                      <option value="default" disabled>
                        Selecione uma opção
                      </option>
                      {db.estado_civil.map((item, index) => {
                        return (
                          <option key={index} value={item.descricao}>
                            {item.descricao}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"escolaridade"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Nível de Escolaridade{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-4"
                      name="nivel_escolaridade"
                      type="text"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dados_usuarios.nivel_escolaridade || "default"
                      }
                    >
                      <option value="default" disabled>
                        Selecione uma opção
                      </option>
                      {db.nivel_escolar.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.nivel}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <Col className="d-grid gap-2">
                <Button
                  type="button"
                  variant="primary"
                  className="rounded-3 me-2"
                  onClick={() => navigate("/register/cpf")}
                  style={{ fontSize: "1rem" }}
                >
                  Voltar
                </Button>
              </Col>

              <Col className="d-grid gap-2">
                <Button
                  style={{ fontSize: "1rem" }}
                  type="submit"
                  variant="primary"
                  className="rounded-3 me-2"
                >
                  Avançar
                </Button>
              </Col>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default Form1;
