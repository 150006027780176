import {CgClose} from 'react-icons/cg'
import { Link } from 'react-router-dom';

const Close = () => {
    return ( 

        <div className='d-flex justify-content-end link-dark mb-4' style={{fontSize:'1rem'}}>
            <Link className='link-dark' to={'/login'}>
                <CgClose/>
            </Link>
        </div>

     );
}
 
export default Close;