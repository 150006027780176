import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Auth() {

    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    }

    const getUser = () => {
        const userString = localStorage.getItem('user')
        const userDetail = JSON.parse(userString)
        return userDetail
    }

    const navigate = useNavigate()
    const [token,setToken] = useState(getToken())
    const [user,setUser] = useState(getUser()) 

    const saveToken = (user,token) => {
        localStorage.setItem('token', JSON.stringify(token))
        localStorage.setItem('user', JSON.stringify(user))
        setToken(token)
        setUser(user)
        navigate('/inicio')
    }

    const logout = () => {
        http.post('/logout',token).then(res => {
        })
        localStorage.clear()
        navigate('/login')    
    }

    const unauthorized = (statusText) => {
        if(statusText === 'Unauthorized') {
            logout()
        }
    }

    const http = axios.create({
        baseURL: process.env.REACT_APP_BACK_CANDIDATO,
        headers:{
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })

    const admin = axios.create({
        baseURL: process.env.REACT_APP_BACK_ADMIN,
        headers:{
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    })

    return {
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        logout,
        unauthorized,
        admin
    }

}
