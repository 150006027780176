import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Auth from "../../../../helpers/Auth";
import Swal from "sweetalert2";
import { useState } from "react";

export default function Recursos({ recurso, setRecurso, onHide, ...rest }) {
  const { logout, token, http } = Auth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function handleRecursoSubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    if (recurso.type.length === 0)
      return setErrorMessage("Selecione um tipo de recurso");
    // if (recurso.data.type !== "application/pdf")
    //   return setErrorMessage("Selecione um Arquivo PDF");
    if (recurso.data.size >= 5 * 1024 * 1024)
      return setErrorMessage("O arquivo deve ter no máximo 5MB");

    switch (recurso.data.type) {
      case "image/png":
        break;
      case "image/jpeg":
        break;
      case "application/pdf":
        break;
      default:
        return setErrorMessage("Selecione um Arquivo PDF ou uma imagem");
    }

    setLoading(true);

    // console.log(recurso.data.name)

    try {
      await http.post(
        "salvar-arquivo-s3",
        {
          inscricao: String(recurso.id),
          file: recurso.data,
          name: recurso.data.name,
          type: recurso.type,
          idUser: recurso.idUser,
        },
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      Swal.fire({
        icon: "success",
        text: "Recurso enviado com sucesso!",
      });
      onHide();
    } catch (error) {
      if (error.response.status === 401) logout();
      if (error.response.status === 400) {
        Swal.fire({
          icon: "info",
          text: "Recurso não habilitado!",
          // confirmButtonColor: "#dc3545",
        });
      } 
      if (error.response.status === 500) {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      {...rest}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setRecurso({ type: "", id: "", data: "" });
        setErrorMessage("");
        onHide();
      }}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Recurso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleRecursoSubmit}
          className="d-flex justify-content-center flex-column"
        >
          <Form.Group className="mb-4" controlId="selectRecurso">
            <Form.Select
              onChange={(e) => setRecurso({ ...recurso, type: e.target.value })}
              defaultValue={recurso.type || "default"}
              required
            >
              <option disabled value="default">
                Selecione um recurso
              </option>

              {/* {recurso.isencao === 1 && (
                <option value="recursos_isencao">Isenção</option>
              )} */}

              {/* {recurso.deficiencia === 1 && (
                <option value="recursos_deficiencia">
                  Condição de Deficiência Física
                </option>
              )} */}

              {/* <option value="recursos_gabarito_preliminar">
                Gabarito Preliminar
              </option> */}

              {/* <option value="recursos_classificacao_preliminar">
                Classificação Preliminar
              </option> */}

              {/* <option value="recursos_edital_preliminar">
                Edital Preliminar de deferimento das incrições
              </option> */}

            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4" controlId="fileRecurso">
            <Form.Control
              type="file"
              aria-describedby="passwordHelpBlock"
              accept=".pdf, image/png, image/jpeg"
              required
              onChange={(e) =>
                setRecurso({ ...recurso, data: e.target.files[0] })
              }
            />
          </Form.Group>

          <Form.Text className="text-center mb-3 text-danger">
            {errorMessage}
          </Form.Text>

          <Button type={loading ? "button" : "submit"}>
            {loading ? <Spinner size="sm" /> : "Enviar"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
