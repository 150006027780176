import { Form } from "react-bootstrap";

export default function File({formik, label, ...rest}) {

//   const handleFileChange = (event) => {
//     const selectedFile = event.target.files[0];
//     setPdf({ ...pdf, [event.target.name]: selectedFile })
//   };

  return (
    <div>
      <span className="fs-6 fw-bold">{label}</span>
      <Form.Group className="mt-2" controlId="Arquivo">
        <Form.Control
          {...rest}
          type="file"
          accept=".pdf"
          required
        />
      </Form.Group>
    </div>
  );
}
