export const acessibilidades = [
  "Mesa para cadeira de rodas",
  "Sala no térreo",
  "Apoio para perna (cadeira extra)",
  "Acessibilidade no local de provas (cadeirantes)",
  "Ledor",
  "Sala para amamentação",
  "Cadeira para canhoto (Não é necessário o envio de laudo médico por se tratar de mera adaptação)",
  "Sala individual (somente para doenças contagiosas ou outras específicas, devidamente comprovadas)",
  "Permissão para uso de Lupa",
  "Auxílio para preenchimento do caderno de textos definitivos",
  "Auxílio para preenchimento do cartão de respostas (candidatos que possuam somente deficiência motora ou alguma limitação física que impeça o preenchimento do cartão)",
  "Auxílio para preenchimento do cartão de respostas (candidatos com deficiência visual parcial ou total)",
  "Prova ampliada (fonte tamanho 18)",
  "Prova ampliada (fonte tamanho 22)",
  "Prova superampliada (fonte tamanho 28)",
  "Tempo adicional – Compensação amamentação",
  "Tempo adicional (uma hora) para portadores com deficiência visual"
];
