import { Container } from "react-bootstrap";
import CardMenu from "../../components/CardMenu";
import Auth from "../../helpers/Auth";

const Home = () => {
  const {
    user: { users_id },
  } = Auth();

  return (
    <Container>
      <span className="fw-bold fs-1 mt-5">Área do Candidato</span>
      <div className="d-flex w-100 justify-content-center mt-5 flex-wrap align-items-center">
        <CardMenu
          path={`/atualizar/${users_id}`}
          title={"Atualizar Meus Dados"}
          content={
            "Mantenha seu cadastro pessoal atualizado como documentos, endereços, e-mail, telefone e etc..."
          }
        />
        <CardMenu
          path={"/inscricao"}
          title={"Inscrever em um concurso"}
          content={
            "Inscrição Online - Permite escolher o concurso, cargo, informações sobre o cargo, local do cargo, local de prova, isenção de taxa de inscrição e necessidades especiais."
          }
        />
        <CardMenu
          path={"/meus-concursos-inscricoes"}
          title={"Meus Concursos"}
          content={
            "Exibe todos os concursos que você esta participando mostrando sua situação em relação a um concurso específico e impressão de boleto."
          }
        />
      </div>
    </Container>
  );
};

export default Home;
