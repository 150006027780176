import { Form } from "react-bootstrap";

const Form3 = ({ formik }) => {

  return (
    <>
      <div className=" mb-0 fw-bold text-break">
        Considerando a possibilidade de alguma das etapas serem realizadas em um
        dia de sábado, informe aqui caso seja membro de grupo Adventista do
        Sétimo Dia
      </div>

      <div className="d-flex mt-4 align-items-center justify-content-center">
        <Form.Group
          className=" fw-bold mb-4 d-flex align-items-center justify-content-center me-2"
          controlId={"nao"}
        >
          <Form.Check
            checked={ formik.values.sabado === 0 ? true : false}
            label="Não"
            inline
            type="radio"
            id={`0`}
            className="me-1"
            name="sabado"
            onChange={(e) => formik.setFieldValue('sabado',parseInt(e.target.value))}
            onBlur={formik.handleBlur}
            value={0}
            isInvalid={
              formik.touched.sabado &&
              formik.errors.sabado
            }
          />
        </Form.Group>

        <Form.Group
          className="fw-bold mb-4 d-flex align-items-center justify-content-center ms-2"
          controlId={"sim"}
        >
          <Form.Check
            checked={ formik.values.sabado === 1 ? true : false}
            label="Sim"
            inline
            type="radio"
            id={`1`}
            className="me-1"
            name="sabado"
            onChange={(e) => formik.setFieldValue('sabado',parseInt(e.target.value))}
            onBlur={formik.handleBlur}
            value={1}
            isInvalid={
              formik.touched.sabado &&
              formik.errors.sabado
            }
          />
        </Form.Group>
      </div>

      {formik.touched.sabado &&
      formik.errors.sabado ? (
        <div className="text-danger fw-bold">
          {formik.errors.sabado}
        </div>
      ) : null}
    </>
  );
};

export default Form3;
