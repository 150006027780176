import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../../../../helpers/Auth";
import axios from "axios";
import Loading from "../../../../components/Loading";
import Swal from "sweetalert2";
import Recursos from "../Recursos";
import { saveAs } from "file-saver";

const Tabela = ({ inscricoes }) => {
  const { logout, token, http } = Auth();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [recurso, setRecurso] = useState({
    id: "",
    type: "",
    data: "",
    isencao: 0,
    deficiencia: 0,
  });

  const http2 = axios.create({
    baseURL: process.env.REACT_APP_BACK_CANDIDATO,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/html",
      Authorization: `Bearer ${token}`,
    },
  });

  function abrirHTMLemNovaAba(htmlPDF) {
    const novaAba = window.open();
    novaAba.document.write(htmlPDF);
    novaAba.document.close();
  }

  const handleGerarPdf = async (inscricao, vencimento) => {
    const submit = {
      users_id: inscricao.users_id,
      valor: inscricao.valor,
      vencimento: vencimento,
      inscricao: inscricao.inscricao,
    };
    setLoading(true);
    await http2
      .post("pagamento", submit)
      .then((resp) => abrirHTMLemNovaAba(resp.data))
      .catch((error) => {
        console.log(error.response.status);
        const texto =
          error.response.status === 400
            ? error.response.data
            : `Algum erro inesperado aconteceu. Tente novamente!`;
        error.response.status === 401
          ? logout()
          : Swal.fire({
              icon: "error",
              text: texto,
              confirmButtonColor: "#dc3545",
            });
      });
    setLoading(false);
  };

  function hundleGerarStatus(inscricao) {
    const {
      isencao,
      isencao_status,
      boleto_vencimento,
      inicio_boleto_indeferido,
      fim_boleto_indeferido,
      vencimento_boleto_indeferido,
    } = inscricao;

    const date = new Date();
    const inicio = new Date(inicio_boleto_indeferido + "T00:00:00");
    const fim = new Date(fim_boleto_indeferido + "T22:59:59");
    const vencimento = new Date(boleto_vencimento + "T23:59:59");

    if (isencao === 0) {
      if (vencimento.getTime() <= date.getTime()) return <span>-</span>;
      return (
        <Link
          className="text-decoration-none"
          onClick={() => handleGerarPdf(inscricao, boleto_vencimento)}
        >
          Gerar
        </Link>
      );
    }

    if (isencao_status === "Indeferido") {
      if (!(inicio <= date && date <= fim)) return <span>-</span>;
      return (
        <Link
          className="text-decoration-none"
          onClick={() =>
            handleGerarPdf(inscricao, vencimento_boleto_indeferido)
          }
        >
          Gerar
        </Link>
      );
    }

    return <span>-</span>;
  }

  function dataHora(value) {
    // 2023-11-10T15:05:31.000000Z
    const data = new Date(value);
    data.setHours(data.getHours() - 4);
    const dataFormatada = data
      .toISOString()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("-");
    const horario = data.toISOString().slice(11, 16);
    return dataFormatada + " / " + horario;
  }

  function statusPagamento(inscricao, tipo = null) {
    if (inscricao.isencao === 1) {
      if (inscricao.isencao_status === "Indeferido") {
        if (inscricao.pagamento === 0)
          return (
            <span className={tipo === "inv" ? "" : "text-danger"}>
              Pendente
            </span>
          );
        return (
          <span className={tipo === "inv" ? "" : "text-success"}>Pago</span>
        );
      }
      return <span>-</span>;
    }
    if (inscricao.pagamento === 0)
      return (
        <span className={tipo === "inv" ? "" : "text-danger"}>Pendente</span>
      );
    return <span className={tipo === "inv" ? "" : "text-success"}>Pago</span>;
  }

  function tipoDeficiencia(inscricao) {
    const { deficiencia, tipo_deficiencia } = inscricao;
    if (deficiencia === 0) return "-";
    return tipo_deficiencia;
  }

  function handleRecurso(inscricao) {
    // const { isencao, deficiencia, users_id, pagamento, isencao_status } = inscricao;
    // if (isencao_status === "Deferido" || pagamento === 1) // Edital Preliminar de deferimento das incrições
    //   return (
    //    <Link
    //      className="text-decoration-none"
    //      onClick={() => {
    //       setRecurso({
    //          ...recurso,
    //        id: inscricao.inscricao,
    //          isencao,
    //         deficiencia,
    //          idUser: users_id,
    //        });
    //        setModalShow(true);
    //      }}
    //    >
    //      Enviar
    //    </Link>
    // );
    return <span>-</span>;
  }

  async function handleCartaoConfirmacao(inscricao, tipo) {
    try {
      setLoading(true);
      const index = inscricao.arquivos_s3[tipo].length;
      const name = inscricao.arquivos_s3[tipo][index - 1];
      const submit = {
        inscricao: inscricao.inscricao,
        tipo_arquivo: tipo,
        nome_arquivo: name,
        idUser: inscricao.users_id,
      };
      const resp = await http.post(`baixar-arquivo-cnd`, submit, {
        responseType: "blob",
      });
      const buffer = resp.data;
      const type = resp.headers["content-type"];
      console.log(type)
      saveAs(new Blob([buffer]), name, { type });
      setLoading(false);
    } catch (err) {
      if (err.response.status === 401) logout();
      if (err.response.status === 400) {
        Swal.fire({
          icon: "error",
          text: err.response.data.message ?? '',
          confirmButtonText: "Ok",
        });
      }
    }
  }

  function colunaCartaoConfirmacao(insc) {
    const { arquivos_s3 } = insc;
    if (arquivos_s3?.['cartao-confirmacao'])
      return (
        <Link
          className="text-decoration-none"
          onClick={() => handleCartaoConfirmacao(insc, 'cartao-confirmacao')}
        >
          Visualizar
        </Link>
      );
    return <span>-</span>;
  }

  if (loading) return <Loading />;
  else
    return (
      <>
        <Recursos
          show={modalShow}
          onHide={() => setModalShow(false)}
          id={modalShow.id}
          recurso={recurso}
          setRecurso={setRecurso}
        />
        <Table responsive hover>
          <thead>
            <tr className="text-center align-middle">
              <th>Inscrição</th>
              <th>Concurso</th>
              <th>Cargo</th>
              <th>Deficiência</th>
              <th>Data/Hora da Inscrição</th>
              <th>Vencimento</th>
              <th>Status da Isenção</th>
              <th>Status do Pagamento</th>
              <th>Boleto</th>
              <th>Recurso</th>
              <th>Cartão de Confirmação</th>
            </tr>
          </thead>

          <tbody>
            {inscricoes.length > 0 &&
              inscricoes.map((inscricao, index) => {
                if (!inscricao.deleted_at)
                  return (
                    <tr key={index} className="text-center align-middle">
                      <td>{String(inscricao.inscricao).padStart(5, "0")}</td>
                      <td>{inscricao.concursoNome}</td>
                      <td>{inscricao.cargoNome}</td>
                      <td>{tipoDeficiencia(inscricao)}</td>
                      <td>{dataHora(inscricao.created_at)}</td>
                      <td>
                        {inscricao.boleto_vencimento
                          .split("-")
                          .reverse()
                          .join("-")}
                      </td>

                      <td>
                        {inscricao.isencao === 1 ? (
                          <span
                            className={
                              inscricao.isencao_status ===
                              "Em análise da comissão"
                                ? ""
                                : inscricao.isencao_status === "Deferido"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {inscricao.isencao_status}
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td>{statusPagamento(inscricao)}</td>
                      <td>{hundleGerarStatus(inscricao)}</td>
                      <td>{handleRecurso(inscricao)}</td>
                      <td>{colunaCartaoConfirmacao(inscricao)}</td>
                    </tr>
                  );
                return (
                  <tr
                    key={index}
                    className="text-center align-middle text-black-50"
                  >
                    <td>{String(inscricao.inscricao).padStart(5, "0")}</td>
                    <td>{inscricao.concursoNome}</td>
                    <td>{inscricao.cargoNome}</td>
                    <td>{tipoDeficiencia(inscricao)}</td>
                    <td>{dataHora(inscricao.created_at)}</td>
                    <td>
                      {inscricao.boleto_vencimento
                        .split("-")
                        .reverse()
                        .join("-")}
                    </td>
                    <td>
                      {inscricao.isencao === 1 ? (
                        <span>{inscricao.isencao_status}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>{statusPagamento(inscricao, "inv")}</td>
                    {/* <td>{handleRecurso(inscricao)}</td> */}
                    {/* <td>{colunaCartaoConfirmacao(inscricao)}</td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </>
    );
};

export default Tabela;
