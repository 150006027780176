import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Button, Card, Col, Container, Form, Spinner } from "react-bootstrap";
import Menu from "../../../../components/Menu";
import Close from "../../../../components/Close";
import Steps from "../Steps";
import { useContext, useState } from "react";
import { RegisterContext } from "../../../../context/RegisterContext";
import Swal from "sweetalert2";
import Auth from "../../../../helpers/Auth";
import { useNavigate } from "react-router-dom";

const Form5 = () => {
  const [showSenha, setShowSenha] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
  const styleBotao =
    window.innerWidth < 768
      ? { width: "30px", background: "#fff", top: "68%", left: "95%" }
      : { width: "35px", background: "#fff", top: "68%", left: "96%" };

  const { usuario, setUsuario, dados_usuarios, loading, setLoading } =
    useContext(RegisterContext);
  const navigate = useNavigate();
  const { http, logout } = Auth();

  async function handleSubmit(e) {
    e.preventDefault();
    if (usuario.email !== usuario.emailconfirm)
      return messageError("Os emails não estão iguais!");
    if (!passwordRegex.test(usuario.senha))
      return messageError(
        "A senha não está adequada!",
        "Ela deve conter 8 ou mais caracteres, pelo menos uma letra minúscula, uma maiúscula e um número."
      );
    if (usuario.senha !== usuario.confirm)
      return messageError("As senhas não estão iguais!");

    try {
      await http.post("register", { usuario, dados_usuarios });
      messageSuccess();
    } catch (erro) {
      if (erro.response.status === 401) return logout();
      if (erro.response.status >= 400 && erro.response.status < 500) {
        return Swal.fire({
          icon: "error",
          text: erro.response.data.message,
          confirmButtonColor: "#dc3545",
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Algum erro com o servidor aconteceu. Tente novamente!",
          confirmButtonColor: "#dc3545",
        });
      }
    }
  }

  async function reenviarEmail(email) {
    try {
      setLoading(true);
      await http.post("reenviar-email-authenticacao", { email: usuario.email });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Algum erro com o servidor aconteceu. Tente novamente!",
        confirmButtonColor: "#dc3545",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
  }

  function messageError(title, message = null) {
    Swal.fire({
      icon: "error",
      title: title,
      text: message,
      confirmButtonColor: "#dc3545",
    });
  }

  function messageSuccess() {
    Swal.fire({
      icon: "success",
      text: "Um e-mail de confirmação foi enviado para o endereço informado. Favor acessar a sua caixa de e-mail e clicar no link enviado para fazer a validação. Caso você não localize o e-mail na caixa de entrada, verifique a sua caixa de spam.",
      confirmButtonText: "Ir para o Login",
      // cancelButtonText: loading ? <Spinner/> : "Reenviar",
      // showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((resp) => {
      if (resp.isConfirmed) return navigate("/login");
      // else return
    });
  }

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <Menu />
      <Container className="p-3 justify-content-center align-items-center d-flex">
        <Card
          className="border-0 shadow-sm rounded-4 p-4"
          style={{
            backgroundColor: "#fff",
            maxWidth: "510px",
            minWidth: "380px",
          }}
        >
          <Form
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
              }
            }}
            className="h-100"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Close />

            <Steps page={5} tamanho={6} />

            <div className="p-2">
              <Form.Group
                className="w-100 m-0 align-content-center mb-4"
                controlId={"email"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  className="rounded-4"
                  name="email"
                  type="text"
                  onChange={handleChange}
                  onPaste={(e) => e.preventDefault()}
                  value={usuario.email}
                  required
                />
              </Form.Group>

              <Form.Group
                className="w-100 m-0 align-content-center mb-4"
                controlId={"emailconfirm"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Confirmar Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-4"
                  name="emailconfirm"
                  type="text"
                  onChange={handleChange}
                  autoComplete="off"
                  onPaste={(e) => e.preventDefault()}
                  value={usuario.emailconfirm}
                  required
                  isInvalid={
                    usuario.emailconfirm.length === 0
                      ? false
                      : usuario.email !== usuario.emailconfirm
                  }
                />
              </Form.Group>

              <div className=" mt-2 mb-3" style={{ fontSize: "0.9rem" }}>
                Digite 8 dígitos, ou mais, contendo pelo menos uma letra
                maiúscula, uma letra minúscula e um número
              </div>

              <Form.Group
                className="w-100 m-0 align-content-center position-relative mb-4"
                controlId={"senha"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Senha <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-4"
                  name="senha"
                  type={showSenha ? "text" : "password"}
                  onChange={handleChange}
                  onPaste={(e) => e.preventDefault()}
                  value={usuario.senha}
                  required
                  isInvalid={
                    usuario.senha.length === 0
                      ? false
                      : !passwordRegex.test(usuario.senha)
                  }
                />
                <Button
                  style={styleBotao}
                  className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                  onClick={() => setShowSenha(!showSenha)}
                >
                  {showSenha ? (
                    <FaEyeSlash fill="#3b3b3b" />
                  ) : (
                    <FaEye fill="#3b3b3b" />
                  )}
                </Button>
              </Form.Group>

              <Form.Group
                className="w-100 m-0 align-content-center position-relative mb-4"
                controlId={"confirm"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Confirmar Senha <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-4"
                  name="confirm"
                  type={showConfirm ? "text" : "password"}
                  onChange={handleChange}
                  onPaste={(e) => e.preventDefault()}
                  value={usuario.confirm}
                  required
                  isInvalid={
                    usuario.confirm.length === 0
                      ? false
                      : usuario.senha !== usuario.confirm
                  }
                />
                <Button
                  style={styleBotao}
                  className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                  onClick={() => setShowConfirm(!showConfirm)}
                >
                  {showConfirm ? (
                    <FaEyeSlash fill="#3b3b3b" />
                  ) : (
                    <FaEye fill="#3b3b3b" />
                  )}
                </Button>
              </Form.Group>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <Col className="d-grid gap-2">
                <Button
                  type="button"
                  variant="primary"
                  className="rounded-3 me-2"
                  onClick={() => navigate("/register/form4")}
                  style={{ fontSize: "1rem" }}
                >
                  Voltar
                </Button>
              </Col>

              <Col className="d-grid gap-2">
                <Button
                  style={{ fontSize: "1rem" }}
                  type="submit"
                  variant="primary"
                  className="rounded-3 me-2"
                >
                  Avançar
                </Button>
              </Col>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default Form5;
