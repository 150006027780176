import { Form } from "react-bootstrap";

const Ciente = ({ formik }) => {
  const hundleClick1 = (e) => {
    const bool = parseInt(e.target.value) === 0 ? 1 : 0;
    formik.setValues({
      ...formik.values,
      concurso: { ...formik.values.concurso, ciente1: bool },
    });
  };

  const hundleClick2 = (e) => {
    const bool = parseInt(e.target.value) === 0 ? 1 : 0;
    formik.setValues({
      ...formik.values,
      concurso: { ...formik.values.concurso, ciente2: bool },
    });
  };

  return (
    <>
      <div className="mb-4 text-break">
        <Form.Check
          className="fw-bold"
          style={{ fontSize: "1rem" }}
          type={"checkbox"}
          id={`ciente1`}
          label={
            "Estou ciente de que a formalização da inscrição implica a aceitação de todas as regras e condições estabelecidas no edital de abertura de inscrição. Estou ciente, ainda, que, caso venha a ser aprovado e convocado, deverei entregar os documentos comprobatórios dos requisitos exigidos para o cargo por ocasião da posse."
          }
          name="concurso.ciente1"
          onChange={hundleClick1}
          onBlur={formik.handleBlur}
          checked={formik.values.concurso?.ciente1 === 1}
          value={formik.values.concurso?.ciente1}
        />
      </div>

      {formik.values.concurso.modalidade === "1" && (
        <div className="mb-4 text-break">
          <Form.Check
            className="fw-bold"
            style={{ fontSize: "1rem" }}
            type={"checkbox"}
            id={`ciente2`}
            label={
              "Neste ato, declaro para os devidos fins de direito e para o concurso a qual estou me inscrevendo, que todos os dados por mim informados são verdadeiros, inclusive que resido na área da comunidade em que irei atuar, desde a data da publicação do edital do concurso, nos termos do art. 6º, I da Lei Federal nº 11.350 de 05 de outubro de 2006."
            }
            name="concurso.ciente2"
            onChange={hundleClick2}
            onBlur={formik.handleBlur}
            checked={formik.values.concurso?.ciente2 === 1}
            value={formik.values.concurso?.ciente2}
          />
        </div>
      )}
    </>
  );
};

export default Ciente;
