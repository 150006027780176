import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SubmitCancel = ({ formik }) => {
  function handleConcluir() {
    const { ciente1, ciente2, modalidade } = formik.values.concurso;
    if (
      (ciente1 === 1 && modalidade !== "1") ||
      (ciente1 === 1 && ciente2 === 1 && modalidade === "1")
    )
      return (
        <Button type="submit" className="rounded-3 ms-2">
          Concluir Inscrição
        </Button>
      );
    else return null;
  }

  return (
    <>
      <div className="mb-4 d-flex justify-content-center align-items-center">
        <Link to={"/home"}>
          <Button type="submit" variant="secondary" className="rounded-3 me-2">
            Cancelar
          </Button>
        </Link>
        {handleConcluir()}
      </div>
    </>
  );
};

export default SubmitCancel;
