import { Alert, Button, Card, Col, Container, Form } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../../helpers/Auth";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

const initialValues = {
  email: "",
  senha: "",
  senha_confirmation: "",
};

const validation = Yup.object().shape({
  email: Yup.string().email("Endereço de email inválido").required(" "),
  senha: Yup.string()
    .required("Por favor, digite a sua senha")
    .min(8, "No mínimo 8 dígitos")
    .matches(/[A-Z]/, "Pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "Pelo menos uma letra minuscula")
    .matches(/[0-9]/, "Pelo menos um número"),

  senha_confirmation: Yup.string()
    .oneOf([Yup.ref("senha"), null], "As senhas não correspondem")
    .required(" "),
});

export default function RedefinirSenha() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { http, logout, user } = Auth();
  const [senha, setSenha] = useState(false);
  const [show, setShow] = useState(false);

  const styleBotao =
    window.innerWidth <= 768
      ? { width: "30px", background: "#fff", left: "93%" }
      : { width: "35px", background: "#fff", left: "95%" };

  const hundleSubmit = async (values) => {
    const config = { params: { token } };
    try {
      await http.post("reset-password", values, config);
      Swal.fire({
        icon: "success",
        text: `Senha alterada com sucesso`,
        confirmButtonText: "Ir para o Login",
      }).then((resp) => {
        if (resp.isConfirmed) navigate("/login");
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: `Algum erro inesperado aconteceu. Tente novamente!`,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Ir para o Login",
      }).then((resp) => {
        if (resp.isConfirmed) navigate("/login");
      });
    }
  };

  return (
    <div style={{ background: "#fafafa" }}>
      <Container className="vw-100 vh-100 justify-content-center align-items-center d-flex">
        <Card
          className="shadow-sm border-0 rounded-4 p-4"
          style={{ background: "#fff", width: "600px" }}
        >
          <div className="fs-3 fw-bold mb-4 text-center">Redefinir senha</div>

          {senha && (
            <div className="text-center">
              <Alert key={"danger"} variant={"danger "}>
                <span className="text-danger">Senha inválida</span>
              </Alert>
            </div>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values) => hundleSubmit(values)}
          >
            {(formik) => (
              <Form
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Form.Group controlId="email" className="position-relative">
                  <p className="m-2 fw-bold" style={{ fontSize: "1.1rem" }}>
                    Digite seu email
                  </p>

                  <Form.Control
                    type="text"
                    placeholder=""
                    className="mb-4 rounded-4"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                  />
                </Form.Group>

                <div className="mt-1" style={{ fontSize: "0.9rem" }}>
                  Digite 8 dígitos, ou mais, contendo pelo menos uma letra
                  maiúscula, uma letra minúscula e um número
                </div>

                <Form.Group
                  controlId="senha"
                  className="mb-4 position-relative"
                >
                  <p className="m-2 fw-bold" style={{ fontSize: "1.1rem" }}>
                    Digite sua nova senha
                  </p>
                  <div className="position-relative">
                    <Form.Control
                      type={show ? "text" : "password"}
                      placeholder=""
                      className="rounded-4"
                      name="senha"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.senha}
                      isInvalid={formik.errors.senha && formik.touched.senha}
                    />
                    <Button
                      style={{ ...styleBotao, top: "50%" }}
                      className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <FaEyeSlash fill="#3b3b3b" />
                      ) : (
                        <FaEye fill="#3b3b3b" />
                      )}
                    </Button>
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="senha_confirmation"
                  className="mb-4 position-relative"
                >
                  <p className="m-2 fw-bold" style={{ fontSize: "1.1rem" }}>
                    Confirme sua nova senha
                  </p>
                  <div className="position-relative">
                    <Form.Control
                      type={show ? "text" : "password"}
                      placeholder=""
                      className="rounded-4"
                      name="senha_confirmation"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.senha_confirmation}
                      isInvalid={
                        formik.errors.senha_confirmation &&
                        formik.touched.senha_confirmation
                      }
                    />
                    <Button
                      style={{ ...styleBotao, top: "50%" }}
                      className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <FaEyeSlash fill="#3b3b3b" />
                      ) : (
                        <FaEye fill="#3b3b3b" />
                      )}
                    </Button>
                  </div>
                </Form.Group>

                <div className="d-flex justify-content-center align-items-center px-5">
                  <Col className="d-grid gap-2">
                    <Button type="submit" className="rounded-3 ms-2">
                      Enviar
                    </Button>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  );
}
