import ProgressBar from 'react-bootstrap/ProgressBar';

const Steps = ({ page, tamanho }) => {

  const now = (page / 5) * 100

  return (
    <ProgressBar now={now}  visuallyHidden className='mb-3'/>
  );
};

export default Steps;
