import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Auth from "../../helpers/Auth";
import CespecSVG from "../../assets/Cespec";

const Menu = () => {
  const { token, logout, user } = Auth();

  const hundleLogout = () => {
    if (token !== undefined) logout();
  };

  if (token)
    return (
      <Navbar
        style={{ background: "#fff" }}
        expand="lg"
        className="shadow-sm mb-4"
      >
        <Container>
          <Navbar.Brand as={Link} to={"/inicio"}>
            <CespecSVG
              width={140}
              height={(140 / 353) * 145}
              viewBox="0 0 300 80"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end fs-6 w-100">
              <NavDropdown
                title={`${user.nome_completo}`}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/trocar-senha">
                  Trocar Senha
                </NavDropdown.Item>
                <NavDropdown.Item onClick={hundleLogout}>
                  <span>Sair</span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  else
    return (
      <Navbar style={{ background: "#fff" }} expand="lg" className="shadow-sm ">
        <Container>
          <Navbar.Brand as={Link} to={"/"}>
            <CespecSVG
              width={140}
              height={(140 / 353) * 145}
              viewBox="0 0 300 80"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
};

export default Menu;
