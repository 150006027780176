import * as Yup from "yup";
import { cpf } from "cpf-cnpj-validator";

export const defaultUsuario = {
  usuario: {
    cpf: "",
    email: "",
    emailconfirm: "",
    senha: "",
    confirm: "",
  },
  dados_usuarios: {
    nome_completo: "",
    data_nascimento: "",
    nome_mae: "",
    nome_pai: "",
    tipo_identidade: "RG",
    identidade: "",
    orgao_emissor: "",
    uf_orgao_emissor: "",
    data_emissao_identidade: "",
    nacionalidade: "",
    naturalidade: "",
    sexo: "",
    estado_civil: "",
    cor_raca: "",
    nivel_escolaridade: "",
    sabado: 0,
    cep: "",
    tipo_logradouro: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    uf: "",
    municipio: "",
    Telefone1: "",
    Telefone2: "",
  },
};

export const validationUsuario = Yup.object().shape({
  usuario: Yup.object().shape({
    cpf: Yup.string()
      .required(" ")
      .test("valid-cpf", "CPF inválido", (value) => {
        return cpf.isValid(value.replace(/\D/g, ""));
      }),
    email: Yup.string().email("Endereço de email inválido").required(" "),
    emailconfirm: Yup.string()
      .oneOf([Yup.ref("email"), null], "Os emails não correspondem")
      .required(" "),
    senha: Yup.string()
      .required("Por favor, digite a sua senha")
      .min(8, "No mínimo 8 dígitos")
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minuscula")
      .matches(/[0-9]/, "A senha deve conter pelo menos um número"),

    confirm: Yup.string()
      .oneOf([Yup.ref("senha"), null], "As senhas não correspondem")
      .required(" "),
  }),
  dados_usuarios: Yup.object().shape({
    nome_completo: Yup.string().required(" "),
    data_nascimento: Yup.date().required(" "),
    nome_mae: Yup.string().required(" "),
    nome_pai: Yup.string(),
    tipo_identidade: Yup.string().required(),
    identidade: Yup.string().required(" "),
    orgao_emissor: Yup.string().required(" "),
    uf_orgao_emissor: Yup.string().required(" "),
    data_emissao_identidade: Yup.date().required(" "),
    nacionalidade: Yup.string().required(" "),
    naturalidade: Yup.string().required(" "),
    sexo: Yup.string().required(" "),
    estado_civil: Yup.string().required(" "),
    cor_raca: Yup.string().required(" "),
    nivel_escolaridade: Yup.string().required(" "),
    sabado: Yup.number()
      .oneOf([0, 1])
      .required("Por favor, selecione uma opção."),
    cep: Yup.string()
      .matches(/^\d{5}\d{3}$/, "CEP inválido")
      .required(" "),
    tipo_logradouro: Yup.string().required(" "),
    endereco: Yup.string().required(" "),
    numero: Yup.string().required(" "),
    complemento: Yup.string(),
    bairro: Yup.string().required(" "),
    uf: Yup.string().max(2).required(" "),
    municipio: Yup.string().required(" "),
    Telefone1: Yup.string().required(" "),
    Telefone2: Yup.string(),
  }),
});

export const defaultInscricao = {
  concurso: {
    users_id: 0,
    concurso: "",
    cargo: "",
    modalidade: "",
    especial: 0,
    ciente1: 0,
    ciente2: 0,
    negro: 0,
    indio: 0,
    deficiencia: 0,
    trans: 0,
    tipo_deficiencia: "",
    deficiencia_descricao: "",
    valor: "",
    cargoNome: "",
    concursoNome: "",
    tipo_isencao: "",
    isencao: 0,
    isencao_file: null,
    deficiencia_file: null,
    vencimento: "",
    data_prova: "",
    hora_prova: "",
    acessibilidades: [],
    inicio_boleto_indeferido: "",
    fim_boleto_indeferido: "",
    vencimento_boleto_indeferido: "",
  },
};

export const validationInscricao = Yup.object()
  .shape({
    concurso: Yup.object().shape({
      users_id: Yup.string().required(),
      concurso: Yup.string().required("Por favor, informe o nome do concurso."),
      cargo: Yup.string().required("Por favor, informe o cargo."),
      especial: Yup.boolean(),
      ciente: Yup.boolean(),
      deficiencia: Yup.boolean(),
      tipo_deficiencia: Yup.string(),
      deficiencia_descricao: Yup.string(),
      negro: Yup.boolean(),
      indio: Yup.boolean(),
      trans: Yup.boolean(),
      isencao: Yup.number()
        .oneOf([0, 1], "Isenção deve ser 0 ou 1")
        .required("Por favor, informe se há isenção."),
      tipo_isencao: Yup.string().when("isencao", {
        is: (value) => value === 1, // Se isencao for 1
        then: (schema) => schema.required("Por favor, informe o tipo de isenção."),
        otherwise: (schema) => schema.default("").nullable(), // Se isencao for 0, tipo_isencao será vazio
      }),
    }),
  })
  .test(
    "especial-acessibilidade",
    "Se o concurso é especial, é necessário selecionar pelo menos uma opção de acessibilidade",
    function (value) {
      const {
        concurso: { especial, acessibilidades },
      } = value;

      if (especial === true && acessibilidades.length === 0) {
        throw this.createError({
          path: "acessibilidade",
          message:
            "Se o concurso é especial, é necessário selecionar pelo menos uma opção de acessibilidade",
        });
      }

      return true;
    }
  );

export const defaultAtualizar = {
  id: "",
  nome_completo: "",
  data_nascimento: "",
  nome_mae: "",
  nome_pai: "",
  identidade: "",
  orgao_emissor: "",
  uf_orgao_emissor: "",
  data_emissao_identidade: "",
  nacionalidade: "",
  naturalidade: "",
  sexo: "",
  estado_civil: "",
  cor_raca: "",
  nivel_escolaridade: "",
  sabado: "",
  cep: "",
  tipo_logradouro: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  uf: "",
  municipio: "",
  Telefone1: "",
  Telefone2: "",
  created_at: "",
  updated_at: "",
};

export const validationAtualizar = Yup.object().shape({
  id: Yup.number(),
  nome_completo: Yup.string().required(" "),
  data_nascimento: Yup.date().required(" "),
  nome_mae: Yup.string().required(" "),
  nome_pai: Yup.string(),
  identidade: Yup.string().required(" "),
  orgao_emissor: Yup.string().required(" "),
  uf_orgao_emissor: Yup.string().required(" "),
  data_emissao_identidade: Yup.date().required(" "),
  nacionalidade: Yup.string().required(" "),
  naturalidade: Yup.string().required(" "),
  sexo: Yup.string().required(" "),
  estado_civil: Yup.string().required(" "),
  cor_raca: Yup.string().required(" "),
  nivel_escolaridade: Yup.string().required(" "),
  sabado: Yup.number()
    .oneOf([0, 1])
    .required("Por favor, selecione uma opção."),
  cep: Yup.string()
    .matches(/^\d{5}-\d{3}$/, "CEP traço")
    .required(" "),
  tipo_logradouro: Yup.string().required(" "),
  endereco: Yup.string().required(" "),
  numero: Yup.number().required(" "),
  complemento: Yup.string(),
  bairro: Yup.string().required(" "),
  uf: Yup.string().max(2).required(" "),
  municipio: Yup.string().required(" "),
  Telefone1: Yup.string().required(" "),
  Telefone2: Yup.string(),
});

export const Email = {
  email: "",
};

export const ValidadeEmail = Yup.object().shape({
  email: Yup.string()
    .email("Endereço de email inválido")
    .required("obrigatorio"),
});

export const EmailChange = {
  email: "",
  newEmail: "",
};

export const ValidadeEmailChange = Yup.object().shape({
  email: Yup.string()
    .email("Endereço de email inválido")
    .required("obrigatorio"),
  newEmail: Yup.string()
    .email("Endereço de email inválido")
    .required("obrigatorio"),
});

export const Senha = {
  senha: "",
  newSenha: "",
  newSenhaConfirm: "",
};

export const ValidadeSenha = Yup.object().shape({
  senha: Yup.string()
    .required("Por favor, digite a sua senha")
    .min(8, "No mínimo 8 dígitos")
    .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minuscula")
    .matches(/[0-9]/, "A senha deve conter pelo menos um número"),
  newSenha: Yup.string()
    .required("Por favor, digite a sua senha")
    .min(8, "No mínimo 8 dígitos")
    .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
    .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minuscula")
    .matches(/[0-9]/, "A senha deve conter pelo menos um número"),
  newSenhaConfirm: Yup.string()
    .oneOf([Yup.ref("newSenha"), null], "As senhas não correspondem")
    .required(" "),
});
