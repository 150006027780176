import { Card, Container, Form } from "react-bootstrap";
import Selecao from "./components/Selecao";
import Especial from "./components/Especial";
import Ciente from "./components/Ciente";
import { useFormik } from "formik";
import { defaultInscricao, validationInscricao } from "../../helpers/Schema";
import { useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import SubmitCancel from "../../components/Botao/SubmitCancel";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";
import File from "../../components/File";
import Deficiente from "./components/Deficiente";

const Inscricao = () => {
  const {
    http,
    admin,
    user: { users_id },
    logout,
  } = Auth();
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  const [concursos, setConcursos] = useState(null);
  const [data, setData] = useState(null);
  const [isencaoFile, setIsencaoFiles] = useState(null);
  const [deficienciaFile, setDeficienciaFiles] = useState(null);
  const maxMB = 5 * 1024 * 1024;

  const [cargo, setCargo] = useState({
    valor: "",
    escolaridade: "",
    boleto_vencimento: "",
    vencimento: "",
    data: "",
    hora: "",
  });

  function fileValidation() {
    if (formik.values.concurso?.isencao !== 0 && isencaoFile.size > maxMB) {
      return true;
    }
    if (
      formik.values.concurso?.deficiencia !== 0 &&
      deficienciaFile.size > maxMB
    ) {
      return true;
    }
    return false;
  }

  const formik = useFormik({
    initialValues: defaultInscricao,
    validationSchema: validationInscricao,
    onSubmit: async (values) => {
      if (fileValidation()) return setloading(false);
      const { concurso } = values;
      concurso["acessibilidades"] = JSON.stringify(concurso["acessibilidades"]);
      try {
        setloading(true);
        await http.post(
          "adicionarConcurso",
          {
            concurso: concurso,
            isencaoFile: isencaoFile,
            deficienciaFile: deficienciaFile,
          },
          {
            headers: { "content-type": "multipart/form-data" },
          }
        );
        navigate("/meus-concursos-inscricoes");
        console.log("submit");
      } catch (error) {
        if (error.response.status === 401) logout();
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            text: "As incrições desse concurso estão encerradas!",
            confirmButtonColor: "#dc3545",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      } finally {
        setloading(false);
      }
    },
  });

  console.log(formik.errors);

  const hundleIdCargos = async (e) => {
    setloading(true);
    const id = e.target.value;
    const conc = concursos.find((item) => String(item.id) === String(id));
    formik.setValues({
      ...formik.values,
      concurso: {
        ...formik.values.concurso,
        concurso: id,
        cargo: "",
        modalidade: String(conc.modalidade),
        concursoNome: conc.nome,
        boleto_vencimento: conc.boleto_vencimento,
        data_prova: conc.data_prova.split("-").reverse().join("/"),
        hora_prova: conc.hora_prova.split("-").reverse().join("/"),
        inicio_boleto_indeferido: conc.inicio_boleto_indeferido,
        fim_boleto_indeferido: conc.fim_boleto_indeferido,
        vencimento_boleto_indeferido: conc.vencimento_boleto_indeferido
      },
    });

    try {
      const response = await admin.get(`concurso/${id}`);
      setData(response.data);
      setCargo({
        ...cargo,
        boleto_vencimento: conc.boleto_vencimento
          .split("-")
          .reverse()
          .join("/"),
        data: conc.data_prova,
        hora: conc.hora_prova,
      });
    } catch (error) {
      if (error.response.status === 401) logout();
      Swal.fire({
        icon: "error",
        text: "Algum erro com o servidor aconteceu. Tente novamente!",
        confirmButtonColor: "#dc3545",
      });
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await http.get("ListarTodosConcursos").catch((error) => {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      });
      formik.setValues({
        ...formik.values,
        concurso: { ...formik.values.concurso, users_id: users_id, cargo: "" },
      });
      setConcursos(response.data);
      setloading(false);
    })();
  }, []);

  if (loading) return <Loading />;

  return (
    <Container>
      <Card className="p-4 border-0 shadow-sm my-5">
        <div className="fw-bold fs-3 mb-3">Inscrição Online</div>

        {concursos.length === 0 && (
          <div className="fw-bold fs-5 mb-3 text-danger">
            Nenhum concurso disponível no momento
          </div>
        )}

        <Form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          encType="multipart/form-data"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }}
        >
          <Selecao
            formik={formik}
            hundleIdCargos={hundleIdCargos}
            concursos={concursos}
            data={data}
            cargo={cargo}
            setCargo={setCargo}
          />
          {formik.values.concurso?.isencao !== 0 && (
            <File
              name="isencao_file"
              label={
                "Envie aqui o documento respectivo de que trata o caso de Isenção de Taxa de inscrição."
              }
              onChange={(e) => setIsencaoFiles(e.target.files[0])}
            />
          )}
          {isencaoFile?.size > maxMB && (
            <div className="mb-5">
              <span className="text-danger" style={{ fontSize: "14px" }}>
                O arquivo deve ter no máximo 5 MB
              </span>
            </div>
          )}
          <Especial formik={formik} cargo={cargo} />
          <Deficiente formik={formik} />
          {formik.values.concurso?.deficiencia !== 0 && (
            <File
              name="deficiencia_file"
              label={
                "Envie aqui o documento respectivo da comprovação de pessoa com deficiência (Laudo Médico)."
              }
              onChange={(e) => setDeficienciaFiles(e.target.files[0])}
            />
          )}
          {deficienciaFile?.size > maxMB && (
            <div className="mb-5">
              <span className="text-danger" style={{ fontSize: "14px" }}>
                O arquivo deve ter no máximo 10 MB
              </span>
            </div>
          )}
          <Ciente formik={formik} />
          <SubmitCancel formik={formik} />
        </Form>
      </Card>
    </Container>
  );
};

export default Inscricao;
