import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Menu from "../../../../components/Menu";
import Close from "../../../../components/Close";
import Steps from "../Steps";
import { useContext } from "react";
import { RegisterContext } from "../../../../context/RegisterContext";
import Swal from "sweetalert2";
import Auth from "../../../../helpers/Auth";
import { useNavigate } from "react-router-dom";

const Form2 = () => {
  const { dados_usuarios, setDados_usuarios, db } = useContext(RegisterContext);
  const navigate = useNavigate();
  const { http, logout } = Auth();

  function handleSubmit(e) {
    e.preventDefault();
    if ((new Date()).getTime() <= (new Date(dados_usuarios.data_emissao_identidade)).getTime()){
      return messageError('Selecione uma data de emissão válida!')
    }
    navigate("/register/form3");
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setDados_usuarios({ ...dados_usuarios, [name]: value });
  }

  function messageError(message) {
    Swal.fire({
      icon: "error",
      text: message,
      confirmButtonColor: "#dc3545",
    });
  }

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <Menu />
      <Container className="p-3 justify-content-center align-items-center d-flex">
        <Card
          className="border-0 shadow-sm rounded-4 p-4"
          style={{
            backgroundColor: "#fff",
            maxWidth: "510px",
            minWidth: "380px",
          }}
        >
          <Form className="h-100" onSubmit={handleSubmit} autoComplete="off">
            <Close />

            <Steps page={2} tamanho={6} />

            <div className="p-2">
              <Form.Group
                className="w-100 m-0 align-content-center mb-4"
                controlId={"identidade"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Documento de Identificação{" "}
                  <span className="text-danger">*</span>
                </Form.Label>

                <div className="mb-3">
                  {["RG", "CNH", "Passaporte"].map((item) => (
                    <Form.Check
                      inline
                      key={item}
                      onChange={handleChange}
                      label={item}
                      checked={
                        dados_usuarios.tipo_identidade === item ? true : false
                      }
                      name="tipo_identidade"
                      type="radio"
                      id={item}
                      value={item}
                      required
                    />
                  ))}
                </div>

                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Número do Documento de Identificação{" "}
                  <span className="text-danger">*</span>
                </Form.Label>

                <Form.Control
                  autoComplete="off"
                  onPaste={(e) => e.preventDefault()}
                  className="rounded-4"
                  name="identidade"
                  type="text"
                  onChange={handleChange}
                  value={dados_usuarios.identidade}
                  required
                />
              </Form.Group>

              <Form.Group
                className="w-100 m-0 align-content-center mb-4"
                controlId={"orgao_emissor"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Órgão Emissor <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  className="rounded-4"
                  name="orgao_emissor"
                  type="text"
                  onChange={handleChange}
                  defaultValue={dados_usuarios.orgao_emissor || "default"}
                  required
                >
                  <option value="default" disabled>Selecione uma opção</option>
                  {db.orgão_expedidors.map((item, index) => {
                    return (
                      <option key={index} value={item.sigla}>
                        {item.sigla}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"data_emissao_identidade"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Data de Emissão <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="rounded-4"
                      name="data_emissao_identidade"
                      type="date"
                      onChange={handleChange}
                      value={dados_usuarios.data_emissao_identidade}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"uf_orgao_emissor"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      UF do Orgão de Emissão{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-4"
                      name="uf_orgao_emissor"
                      type="text"
                      onChange={handleChange}
                      required
                      defaultValue={
                        dados_usuarios.uf_orgao_emissor || "default"
                      }
                    >
                      <option value="default" disabled>Selecione uma opção</option>
                      {db.ufs.map((item, index) => {
                        return (
                          <option key={index} value={item.sigla}>
                            {item.nome}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"nacionalidade"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Nacionalidade <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="rounded-4"
                      name="nacionalidade"
                      type="text"
                      onChange={handleChange}
                      required
                      defaultValue={dados_usuarios.nacionalidade || "default"}
                    >
                      <option value="default" disabled>Selecione uma opção</option>
                      {db.nacionalidade.map((item, index) => {
                        return (
                          <option key={index} value={item.descricao}>
                            {item.descricao}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-4"
                    controlId={"naturalidade"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      Naturalidade <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="rounded-4"
                      name="naturalidade"
                      type="text"
                      onChange={handleChange}
                      required
                      value={dados_usuarios.naturalidade}
                      maxLength={30}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group
                className="w-100 m-0 align-content-center mb-4"
                controlId={"nome_mae"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Nome da Mãe <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="rounded-4"
                  name="nome_mae"
                  type="text"
                  onChange={handleChange}
                  required
                  value={dados_usuarios.nome_mae}
                />
              </Form.Group>

              <Form.Group
                className="w-100 m-0 align-content-center mb-4"
                controlId={"nome_pai"}
              >
                <Form.Label
                  className=" mb-0 fw-bold"
                  style={{ fontSize: "1rem" }}
                >
                  Nome do Pai
                </Form.Label>
                <Form.Control
                  className="rounded-4"
                  name="nome_pai"
                  type="text"
                  onChange={handleChange}
                  value={dados_usuarios.nome_pai}
                />
              </Form.Group>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Col className="d-grid gap-2">
                <Button
                  type="button"
                  variant="primary"
                  className="rounded-3 me-2"
                  onClick={() => navigate("/register/form1")}
                  style={{ fontSize: "1rem" }}
                >
                  Voltar
                </Button>
              </Col>

              <Col className="d-grid gap-2">
                <Button
                  style={{ fontSize: "1rem" }}
                  type="submit"
                  variant="primary"
                  className="rounded-3 me-2"
                >
                  Avançar
                </Button>
              </Col>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default Form2;
