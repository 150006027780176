import { Formik } from "formik";
import { Alert, Button, Card, Col, Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Email, ValidadeEmail } from "../../../helpers/Schema";
import Auth from "../../../helpers/Auth";
import { useState } from "react";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";

const EsqueciSenha = () => {
  const navigate = useNavigate();
  const { http, unauthorized } = Auth();

  const [messageError, setMessageError] = useState(false);
  const [load, setLoad] = useState(false);

  const handleConfirm = () => {
    Swal.fire({
      icon: "success",
      text: "Um email foi enviado para trocar sua senha",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(false);
        navigate("/login");
      }
    });
  };

  const handleError = (message) => {
    Swal.fire({
      icon: "error",
      text: message,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(false);
      }
    });
  };

  const hundleSubmit = async (values) => {
    setLoad(true);
    await http
      .post("password/email", values)
      .then(() => handleConfirm())
      .catch((error) => handleError(error.response.data.message));
  };

  return (
    <div style={{ background: "#fafafa" }}>
      {load ? <Loading /> : null}

      <Container className="vh-100  justify-content-center align-items-center d-flex mb-3">
        <Card
          className="shadow-sm border-0 rounded-4 p-5"
          style={{ background: "#fff" }}
        >
          <div className="fs-3 fw-bold mb-5 text-center">
            Recuperação de Senha
          </div>

          {messageError && (
            <div className="text-center mb-5">
              <Alert key={"danger"} variant={"danger "}>
                <span className="text-danger">Email inválido</span>
              </Alert>
            </div>
          )}

          <Formik
            initialValues={Email}
            validationSchema={ValidadeEmail}
            onSubmit={(values) => hundleSubmit(values)}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} autoComplete="off">
                <Form.Group controlId="formGroupEmail">
                  <p className="m-0 fs-5">
                    Para recuperar a sua senha, informe seu endereço de email
                  </p>
                  <p className="mb-5 fs-5">
                    que nós enviaremos um link para a alteração de sua senha.
                  </p>

                  <Form.Control
                    type="email"
                    placeholder="email"
                    className="mb-5 rounded-4"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                  />
                </Form.Group>

                <div className="mb-2 d-flex justify-content-center align-items-center">
                  <Col>
                    <Link to={"/login"} className="d-grid gap-2 ">
                      <Button
                        type="submit"
                        variant="secondary"
                        className="rounded-3 me-2 border-0"
                      >
                        Voltar
                      </Button>
                    </Link>
                  </Col>
                  <Col className="d-grid gap-2">
                    <Button type="submit" className="rounded-3 ms-2">
                      Enviar
                    </Button>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  );
};

export default EsqueciSenha;
