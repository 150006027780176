import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";

const Form1 = ({ formik, db }) => {
  return (
    <>
      <Form.Group className=" m-0 align-content-center mb-4" controlId={"nome"}>
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          CPF
        </Form.Label>
        <Form.Control
          className="rounded-4"
          name="cpf"
          type="text"
          as={IMaskInput}
          disabled
          mask="000.000.000-00"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cpf}
          isInvalid={formik.touched.cpf && formik.errors.cpf}
        />
      </Form.Group>

      {console.log(formik.errors)}

      <Form.Group className=" m-0 align-content-center mb-4" controlId={"nome"}>
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Email
        </Form.Label>
        <Form.Control
          className="rounded-4"
          name="email"
          type="mail"
          disabled
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isInvalid={formik.touched.email && formik.errors.email}
        />
      </Form.Group>

      <Form.Group className=" m-0 align-content-center mb-4" controlId={"nome"}>
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Nome Completo
        </Form.Label>
        <Form.Control
          className="rounded-4"
          name="nome_completo"
          type="text"
          disabled
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.nome_completo}
          isInvalid={
            formik.touched.nome_completo && formik.errors.nome_completo
          }
        />
      </Form.Group>

      <Row>
        <Col md>
          <Form.Group
            className=" m-0 align-content-center mb-4"
            controlId={"nascimento"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Data de Nascimento
            </Form.Label>
            <Form.Control
              className="rounded-4"
              name="data_nascimento"
              type="date"
              disabled
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.data_nascimento}
              isInvalid={
                formik.touched.data_nascimento && formik.errors.data_nascimento
              }
            />
          </Form.Group>
        </Col>
        <Col md>
          <Form.Group
            className=" m-0 align-content-center mb-4"
            controlId={"sexo"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Sexo
            </Form.Label>
            <Form.Select
              className="rounded-4"
              name="sexo"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.sexo && formik.errors.sexo}
              defaultValue={formik.values.sexo || "default"}
            >
              <option value="default">Selecione uma opção</option>
              {db.sexo.map((item, index) => {
                return (
                  <option key={index} value={item.generos}>
                    {item.generos}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group
        className=" m-0 align-content-center mb-4"
        controlId={"cor-raca"}
      >
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Cor / Raça
        </Form.Label>
        <Form.Select
          className="rounded-4"
          name="cor_raca"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched.cor_raca && formik.errors.cor_raca}
          defaultValue={formik.values.cor_raca || "default"}
        >
          <option value="default">Selecione uma opção</option>
          {db.etnia.map((item, index) => {
            return (
              <option key={index} value={item.nome}>
                {item.nome}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Row>
        <Col md>
          <Form.Group
            className=" m-0 align-content-center mb-4"
            controlId={"telefone1"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Telefone 1
            </Form.Label>
            <Form.Control
              className="rounded-4"
              as={IMaskInput}
              mask={"(00)00000-0000"}
              name="Telefone1"
              type="text"
              // onChange={(e) =>
              //   formik.setFieldValue("telefone1", e.target.value)
              // }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Telefone1}
              isInvalid={formik.touched.Telefone1 && formik.errors.Telefone1}
            />
          </Form.Group>
        </Col>
        <Col md>
          <Form.Group
            className=" m-0 align-content-center mb-4"
            controlId={"telefone2"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Telefone 2
            </Form.Label>
            <Form.Control
              className="rounded-4"
              as={IMaskInput}
              mask={"(00)00000-0000"}
              name="Telefone2"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.Telefone2 || ""}
              isInvalid={formik.touched.Telefone2 && formik.errors.Telefone2}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md>
          <Form.Group
            className=" m-0 align-content-center mb-4"
            controlId={"estado-civil"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: ".9rem" }}>
              Estado Civil
            </Form.Label>
            <Form.Select
              className="rounded-4"
              name="estado_civil"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.estado_civil && formik.errors.estado_civil
              }
              defaultValue={formik.values.estado_civil || "default"}
            >
              <option value="default">Selecione uma opção</option>
              {db.estado_civil.map((item, index) => {
                return (
                  <option key={index} value={item.descricao}>
                    {item.descricao}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md>
          <Form.Group
            className=" m-0 align-content-center mb-4"
            controlId={"escolaridade"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Nível de Escolaridade
            </Form.Label>
            <Form.Select
              className="rounded-4"
              name="nivel_escolaridade"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.nivel_escolaridade &&
                formik.errors.nivel_escolaridade
              }
              defaultValue={formik.values.nivel_escolaridade || "default"}
            >
              <option value="default">Selecione uma opção</option>
              {db.nivel_escolar.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.nivel}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default Form1;
