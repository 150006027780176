import { Col, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";

const Form2 = ({ formik, db }) => {
  const hundleIdentidade = (e) => {
    formik.setValues({
      ...formik.values,
      // identidade: e.target.value.replace(/\D/g, ""),
      identidade: e.target.value,
    });
  };

  return (
    <>
      <Form.Group
        className="w-100 m-0 align-content-center mb-4"
        controlId={"identidade"}
      >
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Identidade
        </Form.Label>
        <Form.Control
          className="rounded-4"
          name="identidade"
          type="text"
          onChange={hundleIdentidade}
          onBlur={formik.handleBlur}
          value={formik.values.identidade}
          isInvalid={formik.touched.identidade && formik.errors.identidade}
        />
      </Form.Group>

      <Form.Group
        className="w-100 m-0 align-content-center mb-4"
        controlId={"orgao_emissor"}
      >
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Orgão Emissor
        </Form.Label>
        <Form.Select
          className="rounded-4"
          name="orgao_emissor"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={
            formik.touched.orgao_emissor && formik.errors.orgao_emissor
          }
          defaultValue={formik.values.orgao_emissor || "default"}
        >
          <option value="default">Selecione uma opção</option>
          {db.orgão_expedidors.map((item, index) => {
            return (
              <option key={index} value={item.sigla}>
                {item.sigla}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>

      <Row>
        <Col md>
          <Form.Group
            className="w-100 m-0 align-content-center mb-4"
            controlId={"data_emissao_identidade"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Data de Emissão
            </Form.Label>
            <Form.Control
              className="rounded-4"
              name="data_emissao_identidade"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.data_emissao_identidade}
              isInvalid={
                formik.touched.data_emissao_identidade &&
                formik.errors.data_emissao_identidade
              }
            />
          </Form.Group>
        </Col>
        <Col md>
          <Form.Group
            className="w-100 m-0 align-content-center mb-4"
            controlId={"uf_orgao_emissor"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              UF do Orgão de Emissão
            </Form.Label>
            <Form.Select
              className="rounded-4"
              name="uf_orgao_emissor"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.uf_orgao_emissor &&
                formik.errors.uf_orgao_emissor
              }
              defaultValue={formik.values.uf_orgao_emissor || "default"}
            >
              <option value="default">Selecione uma opção</option>
              {db.ufs.map((item, index) => {
                return (
                  <option key={index} value={item.sigla}>
                    {item.nome}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md>
          <Form.Group
            className="w-100 m-0 align-content-center mb-4"
            controlId={"nacionalidade"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Nacionalidade
            </Form.Label>
            <Form.Select
              className="rounded-4"
              name="nacionalidade"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.nacionalidade && formik.errors.nacionalidade
              }
              defaultValue={formik.values.nacionalidade || "default"}
            >
              <option value="default">Selecione uma opção</option>
              {db.nacionalidade.map((item, index) => {
                return (
                  <option key={index} value={item.descricao}>
                    {item.descricao}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        {console.log(formik.values)}
        <Col md>
          <Form.Group
            className="w-100 m-0 align-content-center mb-4"
            controlId={"naturalidade"}
          >
            <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
              Naturalidade <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="rounded-4"
              name="naturalidade"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              value={formik.values.naturalidade}
              maxLength={30}
              isInvalid={
                formik.touched.naturalidade && formik.errors.naturalidade
              }
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group
        className="w-100 m-0 align-content-center mb-4"
        controlId={"nome_mae"}
      >
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Nome da Mãe
        </Form.Label>
        <Form.Control
          className="rounded-4"
          name="nome_mae"
          type="text"
          disabled
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.nome_mae}
          isInvalid={formik.touched.nome_mae && formik.errors.nome_mae}
        />
      </Form.Group>

      <Form.Group
        className="w-100 m-0 align-content-center mb-4"
        controlId={"nome_pai"}
      >
        <Form.Label className=" mb-0 fw-bold" style={{ fontSize: "1rem" }}>
          Nome do Pai
        </Form.Label>
        <Form.Control
          className="rounded-4"
          name="nome_pai"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.nome_pai}
          isInvalid={formik.touched.nome_pai && formik.errors.nome_pai}
        />
      </Form.Group>
    </>
  );
};

export default Form2;
