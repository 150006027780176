import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";


const CardMenu = ({title,content,path}) => {
    return ( 

        <Card className="p-3 shadow-sm border-0 mb-4 mx-2" style={{ width:'300px', height:'280px'}} >
            <Link to={path} className="fs-4 mb-3 text-decoration-none">{title}</Link>
            <span style={{fontSize:'1rem'}}>{content}</span>
        </Card>

     );
}
 
export default CardMenu;