import { Link, useNavigate, useParams } from "react-router-dom";
import Auth from "../../helpers/Auth";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import Form1 from "./components/Form_1";
import { validationAtualizar } from "../../helpers/Schema";
import Form2 from "./components/Form_2";
import Form3 from "./components/Form_3";
import Form4 from "./components/Form_4";
import Loading from "../../components/Loading";
import Swal from "sweetalert2";

const Atualizar = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { http, logout } = Auth();

  const [loading, setLoading] = useState(true);
  const [database, setDatabase] = useState(null);
  const [usuario, setUsuario] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const res = await http.get(`procurar-candidato/${id}`);
        const filtrar = res.data.usuario;
        Object.keys(filtrar).forEach((key) => {
          if (filtrar[key] === null) {
            filtrar[key] = "";
          }
        });
        setUsuario(filtrar);

        const response = await http.get("/pegar-dados-uf");
        setDatabase(response.data);
      } catch (error) {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const hundleSubmit = async (values) => {
    console.log(values);
    await http
      .put(`editar-dados/${id}`, values)
      .then(() => navigate("/home"))
      .catch((error) => {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
      });
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Container>
        <Card className="border-0 shadow-sm rounded-4 p-4 mt-5">
          <Formik
            initialValues={usuario}
            validationSchema={validationAtualizar}
            onSubmit={(values) => hundleSubmit(values)}
          >
            {(formik) => (
              <Form 
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Row>
                  <Col md>
                    <Form1 formik={formik} db={database} />
                    <Form2 formik={formik} db={database} />
                  </Col>
                  <Col md>
                    <Form4 formik={formik} db={database} />
                    <Form3 formik={formik} db={database} />
                    <div className="mb-4 d-flex justify-content-center align-items-center">
                      <Link to={"/home"}>
                        <Button
                          type="submit"
                          variant="secondary"
                          className="rounded-3 me-2"
                        >
                          Cancelar
                        </Button>
                      </Link>
                      <Button type="submit" className="rounded-3 ms-2">
                        Atualizar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    );
  }
};

export default Atualizar;
