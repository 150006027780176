import { Col, Form, Row } from "react-bootstrap";
import ListaDeficiencias from "./ListaDeficiencias";

export default function Deficiente({ formik }) {

  const hundleClickRadio = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name.split(".")[1];
    formik.setValues({
      ...formik.values,
      concurso: { ...formik.values.concurso, [name]: value },
    });
  };

  return (
    <div className="mb-4">
      <Row className="mb-4 align-items-center">
        <Col md={5} className="fw-bold" style={{ fontSize: "1rem" }}>
          Deseja concorrer às vagas destinadas às pessoas com deficiência?
        </Col>
        <Col md={2} className="p-0 d-flex gap-4 ms-2">
          <Form.Check
            type={"radio"}
            id={`def-nao`}
            label={`Não`}
            name={"concurso.deficiencia"}
            value={0}
            checked={formik.values.concurso?.deficiencia === 0 ? true : false}
            onChange={hundleClickRadio}
            onBlur={formik.handleBlur}
          />
          <Form.Check
            type={"radio"}
            id={`def-sim`}
            label={`Sim`}
            name={"concurso.deficiencia"}
            value={1}
            checked={formik.values.concurso?.deficiencia === 1 ? true : false}
            onChange={hundleClickRadio}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      {formik.values.concurso?.deficiencia !== 0 && (
        <ListaDeficiencias formik={formik} />
      )}
    </div>
  );
}
