import { Form } from "react-bootstrap";

const ListaDeficiencias = ({ formik }) => {
  return (
    <div className="mt-3">
      <div className="d-flex align-items-center mb-2 flex-wrap">
        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Auditiva Parcial' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Auditiva Parcial"
          className="me-3"
          type={"radio"}
          value={"Auditiva Parcial"}
          label={"Auditiva Parcial"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Auditiva Total' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Auditiva Total"
          className="me-3"
          type={"radio"}
          value={"Auditiva Total"}
          label={"Auditiva Total"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Motora' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Motora"
          className="me-3"
          type={"radio"}
          value={"Motora"}
          label={"Motora"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Visual Parcial' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Visual Parcial"
          className="me-3"
          type={"radio"}
          value={"Visual Parcial"}
          label={"Visual Parcial"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Visual Total' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Visual Total"
          className="me-3"
          type={"radio"}
          value={"Visual Total"}
          label={"Visual Total"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Mental' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Mental"
          className="me-3"
          type={"radio"}
          value={"Mental"}
          label={"Mental"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Múltipla' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Múltipla"
          className="me-3"
          type={"radio"}
          value={"Múltipla"}
          label={"Múltipla"}
        />

        <Form.Check
          inline
          checked={formik.values.concurso.tipo_deficiencia === 'Outra' ? true : false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="concurso.tipo_deficiencia"
          id="Outra"
          className="me-3"
          type={"radio"}
          value={"Outra"}
          label={"Outra"}
        />
      </div>
    </div>
  );
};

export default ListaDeficiencias;
