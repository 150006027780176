import { Button, Card, Container } from "react-bootstrap";
import { useFormik } from "formik";
import { defaultInscricao, validationInscricao } from "../../helpers/Schema";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../helpers/Auth";
import Tabela from "./components/Tabela";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Pagina from "../../components/Pagina";
import Swal from "sweetalert2";

const MeusConcursos = () => {
  const navigate = useNavigate;
  const {
    http,
    user: { users_id },
    logout,
  } = Auth();
  const [loading, setloading] = useState(true);
  const [inscricoes, setInscricoes] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const handleChangePage = useCallback((page) => {
    setPage(page);
  }, []);

  const formik = useFormik({
    initialValues: defaultInscricao,
    validationSchema: validationInscricao,

    onSubmit: async (values) => {
      navigate("/login");
    },
  });

  useEffect(() => {
    (async () => {
      setloading(true);
      const response = await http
        .get(`lista-concursos-usuario/${users_id}`, {
          params: { page, qtd: 10 },
        })
        .catch((error) => {
          if (error.response.status === 401) logout();
          else {
            Swal.fire({
              icon: "error",
              text: "Algum erro com o servidor aconteceu. Tente novamente!",
              confirmButtonColor: "#dc3545",
            });
          }
        });
      setInscricoes(response.data.data);
      setTotalPage(response.data.totalPaginas);
      setloading(false);
    })();
  }, [page]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container className="my-5">
      <Card className="p-4 border-0 shadow-sm">
        <div className="fw-bold fs-3 mb-5">Meus Concursos/Inscrições</div>
        <span className="fw-bold fs-5">BOLETO BANCÁRIO</span>
        <span className="mb-4">
          O pagamento do boleto bancário poderá ser realizado em qualquer
          banco, exclusivamente até a data do vencimento do boleto, respeitando
          o horário de funcionamento de cada banco.
        </span>

        <Tabela inscricoes={inscricoes} />

        {totalPage > 1 && (
          <Pagina
            total={totalPage}
            current={page}
            onChangePage={handleChangePage}
          />
        )}

        <div className="d-flex justify-content-center align-items-center mt-4">
          <Link to={"/home"}>
            <Button
              type="submit"
              variant="secondary"
              className="rounded-3 me-2"
            >
              Voltar
            </Button>
          </Link>
          {/* {formik.values.ciente === 1 && <Button type="submit" className='rounded-4 ms-2'>Inscrever</Button> } */}
        </div>
      </Card>
    </Container>
  );
};

export default MeusConcursos;
