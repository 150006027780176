import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Menu from "../../../../components/Menu";
import Close from "../../../../components/Close";
import Steps from "../Steps";
import { useContext } from "react";
import { RegisterContext } from "../../../../context/RegisterContext";
import Swal from "sweetalert2";
import Auth from "../../../../helpers/Auth";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/Loading";
import { IMaskInput } from "react-imask";
import { useEffect } from "react";

const Form4 = () => {
  const { dados_usuarios, setDados_usuarios, db, loading, setLoading } =
    useContext(RegisterContext);
  const navigate = useNavigate();
  const { http, logout } = Auth();

  const ValidarCEP = () => {
    setLoading(true);
    try {
      const cep = dados_usuarios.cep.replace(/\D/g, "");
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((resp) => resp.text()) // Obtenha a resposta como texto
        .then((text) => JSON.parse(text)) // Analise a resposta como JSON
        .then((data) => {
          setDados_usuarios({
            ...dados_usuarios,
            cep: dados_usuarios.cep,
            endereco: data.logradouro,
            bairro: data.bairro,
            uf: data.uf,
            municipio: data.localidade,
            tipo_logradouro: data.logradouro.split(" ")[0],
          });
        });
    } catch {
      messageError("CEP inválido");
    } finally {
      setLoading(false);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    console.log(dados_usuarios);
    navigate("/register/form5");
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setDados_usuarios({ ...dados_usuarios, [name]: value });
  }

  function messageError(message) {
    Swal.fire({
      icon: "error",
      text: message,
      confirmButtonColor: "#dc3545",
    });
  }

  useEffect(() => {
    const validarCep = async () => {
      const cep = dados_usuarios.cep.replace(/\D/g, "");
      const apiUrl = `https://viacep.com.br/ws/${cep}/json/`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        setDados_usuarios({
          ...dados_usuarios,
          cep: dados_usuarios.cep,
          endereco: data.logradouro,
          bairro: data.bairro,
          uf: data.uf,
          municipio: data.localidade,
          tipo_logradouro: data.logradouro.split(" ")[0],
        })

        if (data.erro) {
          console.log('CEP inválido');
        } else {
          console.log('CEP válido:', data);
        }
      } catch (error) {
        console.error('Erro na chamada à API:', error);
      }
    };

    // Define um temporizador para chamar a função validarCep após 3 segundos de inatividade
    const timeout = setTimeout(() => {
      validarCep();
    }, 1000);

    // Limpa o temporizador se o componente for desmontado ou se o cep for alterado antes de 3 segundos
    return () => clearTimeout(timeout);
  }, [dados_usuarios.cep]);

  if (loading)
    return (
      <div className="w-100">
        <Loading />
      </div>
    );
  else
    return (
      <div style={{ backgroundColor: "#fafafa" }}>
        <Menu />
        <Container className="p-3 justify-content-center align-items-center d-flex">
          <Card
            className="border-0 shadow-sm rounded-4 p-4"
            style={{
              backgroundColor: "#fff",
              maxWidth: "510px",
              minWidth: "380px",
            }}
          >
            <Form className="h-100" onSubmit={handleSubmit} autoComplete="off">
              <Close />

              <Steps page={4} tamanho={6} />

              <div className="p-2">
                <Form.Group
                  className="w-100 m-0 align-content-center mb-4"
                  controlId={"CEP"}
                >
                  <Form.Label
                    className=" mb-0 fw-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    CEP <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()}
                    as={IMaskInput}
                    mask={"00000-000"}
                    className="rounded-4"
                    name="cep"
                    type="text"
                    onChange={handleChange}
                    value={dados_usuarios.cep}
                    required
                  />
                </Form.Group>

                <Form.Group
                  className="w-100 m-0 align-content-center mb-4"
                  controlId={"endereco"}
                >
                  <Form.Label
                    className=" mb-0 fw-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    Endereço <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    className="rounded-4"
                    name="endereco"
                    type="text"
                    onChange={handleChange}
                    value={dados_usuarios.endereco}
                    required
                  />
                </Form.Group>

                <Row>
                  <Col sm>
                    <Form.Group
                      className="w-100 m-0 align-content-center mb-4"
                      controlId={"tipo_logradouro"}
                    >
                      <Form.Label
                        className=" mb-0 fw-bold"
                        style={{ fontSize: "1rem" }}
                      >
                        Tipo de Logradouro{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        className="rounded-4"
                        name="tipo_logradouro"
                        type="text"
                        onChange={handleChange}
                        value={dados_usuarios.tipo_logradouro}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group
                      className="w-100 m-0 align-content-center mb-4"
                      controlId={"numero"}
                    >
                      <Form.Label
                        className=" mb-0 fw-bold"
                        style={{ fontSize: "1rem" }}
                      >
                        Número <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        className="rounded-4"
                        name="numero"
                        type="text"
                        onChange={handleChange}
                        value={dados_usuarios.numero}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group
                  className="w-100 m-0 align-content-center mb-4"
                  controlId={"complemento"}
                >
                  <Form.Label
                    className=" mb-0 fw-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    Complemento
                  </Form.Label>

                  <Form.Control
                    className="rounded-4"
                    name="complemento"
                    type="text"
                    onChange={handleChange}
                    value={dados_usuarios.complemento}
                  />
                </Form.Group>

                <Form.Group
                  className="w-100 m-0 align-content-center mb-4"
                  controlId={"bairro"}
                >
                  <Form.Label
                    className=" mb-0 fw-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    Bairro <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    className="rounded-4"
                    name="bairro"
                    type="text"
                    onChange={handleChange}
                    value={dados_usuarios.bairro}
                    required
                  />
                </Form.Group>

                <Row>
                  <Col sm>
                    <Form.Group
                      className="w-100 m-0 align-content-center mb-4"
                      controlId={"uf"}
                    >
                      <Form.Label
                        className=" mb-0 fw-bold"
                        style={{ fontSize: "1rem" }}
                      >
                        Unidade Federativa{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        as={IMaskInput}
                        mask={"aa"}
                        className="rounded-4"
                        name="uf"
                        type="text"
                        onChange={handleChange}
                        value={dados_usuarios.uf}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col sm>
                    <Form.Group
                      className="w-100 m-0 align-content-center mb-4"
                      controlId={"municipio"}
                    >
                      <Form.Label
                        className="mb-0 fw-bold"
                        style={{ fontSize: "1rem" }}
                      >
                        Município <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        className="rounded-4"
                        name="municipio"
                        type="text"
                        onChange={handleChange}
                        value={dados_usuarios.municipio}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Col className="d-grid gap-2">
                  <Button
                    type="button"
                    variant="primary"
                    className="rounded-3 me-2"
                    onClick={() => navigate("/register/form2")}
                    style={{ fontSize: "1rem" }}
                  >
                    Voltar
                  </Button>
                </Col>

                <Col className="d-grid gap-2">
                  <Button
                    style={{ fontSize: "1rem" }}
                    type="submit"
                    variant="primary"
                    className="rounded-3 me-2"
                  >
                    Avançar
                  </Button>
                </Col>
              </div>
            </Form>
          </Card>
        </Container>
      </div>
    );
};

export default Form4;
