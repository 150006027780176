import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { NumericFormat } from "react-number-format";

function Selecao({ formik, hundleIdCargos, concursos, data, cargo, setCargo }) {
  const [descricao, setDescricao] = useState(false);

  function handleCargo(e) {
    const { value } = e.target;
    const filtro = data.cargos.find(
      (item) => String(item.id) === String(value)
    );
    console.log(filtro);
    setCargo({
      ...cargo,
      escolaridade: filtro.escolaridade,
      valor: filtro.valor,
      vencimento: filtro.vencimento,
    });
    formik.setValues({
      ...formik.values,
      concurso: {
        ...formik.values.concurso,
        valor: filtro.valor,
        cargoNome: filtro.nome,
        cargo: String(filtro.id),
        vencimento: filtro.vencimento
      },
    });
    setDescricao(true);
  }

  const hundleClickRadio = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name.split(".")[1];
    formik.setValues({
      ...formik.values,
      concurso: { ...formik.values.concurso, [name]: value },
    });
  };

  function formatReal(value) {
    return (
      <NumericFormat
        value={value}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={" R$"}
        decimalScale={2}
        fixedDecimalScale={true}
        renderText={(formattedValue) => <span>{formattedValue}</span>}
      />
    );
  }

  return (
    <div className="mb-5">
      <Form.Group className="mb-3" controlId="concurso">
        <Form.Label className="fw-bold" style={{ fontSize: "1.1rem" }}>
          Concurso
        </Form.Label>

        <Form.Select
          className="rounded-4"
          name="concurso.concurso"
          onChange={hundleIdCargos}
          onBlur={formik.handleBlur}
          value={formik.values.concurso?.concurso || "default"}
          isInvalid={
            formik.touched.concurso?.concurso &&
            formik.errors.concurso?.concurso
          }
        >
          <option value={"default"} disabled></option>
          {concursos.length > 0 &&
            concursos.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.nome}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>

      {data?.cargos.length === 0 ? (
        <div className="fw-bold fs-5 mb-3 text-danger">
          Nenhum cargo disponível no momento
        </div>
      ) : null}

      {data?.cargos && (
        <Form.Group className="mb-2" controlId="cargos">
          <Form.Label className="fw-bold" style={{ fontSize: "1.1rem" }}>
            Cargo
          </Form.Label>

          <Form.Select
            disabled={data.cargos.length > 0 ? false : true}
            className="rounded-4"
            name="concurso.cargo"
            onChange={handleCargo}
            onBlur={formik.handleBlur}
            value={formik.values.concurso?.cargo || "default"}
            isInvalid={
              formik.touched.concurso?.cargo && formik.errors.concurso?.cargo
            }
          >
            <option value={"default"} disabled></option>
            {data.cargos &&
              data.cargos.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.nome}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>
      )}

      {descricao && (
        <div className="mb-3">
          <div>
            <strong>Vencimento do Cargo:</strong>
            {formatReal(cargo.vencimento)}
          </div>
          <div>
            <strong>Taxa de Inscrição:</strong> {formatReal(cargo.valor)}
          </div>
          <div>
            <strong>Requisitos Exigidos:</strong> {cargo.escolaridade}
          </div>
          <div>
            <strong>Vencimento Boleto:</strong>
            {cargo.boleto_vencimento.split("-").reverse().join("/")}
          </div>
          <div>
            <strong>Data da Prova:</strong>{" "}
            {cargo.data.split("-").reverse().join("/")}
          </div>
          <div>
            <strong>Horário da Prova:</strong> {cargo.hora.replace(':','h')}
          </div>
        </div>
      )}

      {data?.isencoes && data?.isencoes.length > 0 && (
        <>
          <Row className="mb-2 align-items-center">
            <Col md={5} className="fw-bold" style={{ fontSize: "1rem" }}>
              Deseja requerer alguma isenção?
            </Col>
            <Col md={2} className="p-0 d-flex gap-4 ms-2">
              <Form.Check
                type={"radio"}
                id={`isenc-nao`}
                label={`Não`}
                name={"concurso.isencao"}
                value={0}
                checked={formik.values.concurso?.isencao === 0 ? true : false}
                onChange={hundleClickRadio}
                onBlur={formik.handleBlur}
              />
              <Form.Check
                type={"radio"}
                id={`isenc-sim`}
                label={`Sim`}
                name={"concurso.isencao"}
                value={1}
                checked={formik.values.concurso?.isencao === 1 ? true : false}
                onChange={hundleClickRadio}
                onBlur={formik.handleBlur}
              />
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="isencoes">
            {formik.values.concurso?.isencao !== 0 &&
              data.isencoes.map((isencao, index) => {
                if (!isencao.nome || !isencao.descricao) return null;
                return (
                  <div key={index}>
                    <Form.Check
                      checked={
                        formik.values.concurso?.tipo_isencao === isencao?.nome
                          ? true
                          : false
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.touched.concurso?.tipo_isencao &&
                        formik.errors.concurso?.tipo_isencao
                      }
                      name="concurso.tipo_isencao"
                      id={isencao.nome}
                      className="me-3 fw-bold"
                      type={"radio"}
                      value={isencao.nome}
                      label={isencao.nome}
                    />
                    <div
                      className="text-secondary mb-4"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {isencao.descricao}
                    </div>
                  </div>
                );
              })}
          </Form.Group>
        </>
      )}
    </div>
  );
}

export default Selecao;
