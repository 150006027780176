import { Formik } from "formik";
import { Button, Card, Col, Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Email, ValidadeEmail } from "../../helpers/Schema";
import Auth from "../../helpers/Auth";
import { useState } from "react";
import Swal from "sweetalert2";
import Loading from "../../components/Loading";

const EmailAutenticacao = () => {
  const navigate = useNavigate();
  const { http } = Auth();

  const [load, setLoad] = useState(false);

  const handleConfirm = () => {
    Swal.fire({
      icon: "success",
      text: "Um email de autenticação foi enviado!",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoad(false);
        navigate("/login");
      }
    });
  };

  const handleError = (error) => {
    console.log(error);
    if (error.response.status >= 500) {
      Swal.fire({
        icon: "error",
        text: "Algum erro com o servidor aconteceu. Tente novamente!",
        confirmButtonText: "Ok",
      });
    } else {
      const { message } = error.response.data;
      Swal.fire({
        icon: "error",
        text: message,
        confirmButtonText: "Ok",
      });
    }
  };

  const hundleSubmit = async (values) => {
    setLoad(true);
    await http
      .post("reenviar-email-authenticacao", values)
      .then(() => handleConfirm())
      .catch((error) => handleError(error));
  };

  return (
    <div style={{ background: "#fafafa" }}>
      {load ? <Loading /> : null}

      <Container className="vh-100  justify-content-center align-items-center d-flex mb-3">
        <Card
          className="shadow-sm border-0 rounded-4 p-5"
          style={{ background: "#fff" }}
        >
          <div className="fs-3 fw-bold mb-5 text-center">
            Enviar email de autenticação
          </div>

          <Formik
            initialValues={Email}
            validationSchema={ValidadeEmail}
            onSubmit={(values) => hundleSubmit(values)}
          >
            {(formik) => (
              <Form
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Form.Group controlId="formGroupEmail">
                  <p className="m-0 fs-5 mb-4">
                    Para autenticar seu email, digite-o corretamente abaixo.
                  </p>

                  <Form.Control
                    type="email"
                    placeholder="email"
                    className="mb-5 rounded-4"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                  />
                </Form.Group>

                <div className="mb-2 d-flex justify-content-center align-items-center">
                  <Col>
                    <Link to={"/login"} className="d-grid gap-2 ">
                      <Button
                        type="submit"
                        variant="secondary"
                        className="rounded-3 me-2 border-0"
                      >
                        Voltar
                      </Button>
                    </Link>
                  </Col>
                  <Col className="d-grid gap-2">
                    <Button type="submit" className="rounded-3 ms-2">
                      Enviar
                    </Button>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  );
};

export default EmailAutenticacao;
