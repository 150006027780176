import { createContext } from "react";
import { useState } from "react";

export const RegisterContext = createContext();

export default function RegisterProvider({ children }) {

  const [usuario, setUsuario] = useState({
    cpf: "",
    email: "",
    emailconfirm: "",
    senha: "",
    confirm: "",
  });

  const [dados_usuarios, setDados_usuarios] = useState({
    nome_completo: "",
    data_nascimento: "",
    nome_mae: "",
    nome_pai: "",
    tipo_identidade: "RG",
    identidade: "",
    orgao_emissor: "",
    uf_orgao_emissor: "",
    data_emissao_identidade: "",
    nacionalidade: "",
    naturalidade: "",
    sexo: "",
    estado_civil: "",
    cor_raca: "",
    nivel_escolaridade: "",
    sabado: 0,
    cep: "",
    tipo_logradouro: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    uf: "",
    municipio: "",
    Telefone1: "",
    Telefone2: "",
  });

  const [db, setDatabase] = useState(null);
  const [loading, setLoading] = useState(true);

  const valor = {
    usuario,
    setUsuario,
    dados_usuarios,
    setDados_usuarios,
    db,
    setDatabase,
    loading,
    setLoading
  };

  return (
    <RegisterContext.Provider value={valor}>
      {children}
    </RegisterContext.Provider>
  );
}
