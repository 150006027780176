import { Col, Form, Row } from "react-bootstrap";
import { acessibilidades } from "../../../../json";
import ListaEspecial from "../Deficiente/ListaDeficiencias";

const Especial = ({ formik, cargo: { boleto_vencimento } }) => {
  // const keys = Object.keys(formik.initialValues.acessibilidade);

  // const hundleClickBox = (e) => {
  //   const name = e.target.name.split(".")[1];
  //   const bool = parseInt(e.target.value) === 0 ? 1 : 0;
  //   formik.setValues({
  //     ...formik.values,
  //     acessibilidade: { ...formik.values.acessibilidade, [name]: bool },
  //   });
  // };

  function addEspecial(e) {
    const { value } = e.target;
    formik.setValues((prevValues) => ({
      ...prevValues,
      concurso: {
        ...prevValues.concurso,
        acessibilidades: e.target.checked
          ? [...prevValues.concurso.acessibilidades, value]
          : prevValues.concurso.acessibilidades.filter((item) => item !== value),
      },
    }));
  }

  const hundleClickRadio = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name.split(".")[1];
    formik.setValues({
      ...formik.values,
      concurso: { ...formik.values.concurso, [name]: value },
    });
  };

  return (
    <div className="mb-4">
      <Row className="mb-4 align-items-center">
        <Col md={5} className="fw-bold" style={{ fontSize: "1rem" }}>
          Necessita de algum atendimento especial para realização da prova?
        </Col>
        <Col md={2} className="p-0 d-flex gap-4 ms-2">
          <Form.Check
            type={"radio"}
            id={`esp-nao`}
            label={`Não`}
            name={"concurso.especial"}
            value={0}
            checked={formik.values.concurso?.especial === 0 ? true : false}
            onChange={hundleClickRadio}
            onBlur={formik.handleBlur}
          />
          <Form.Check
            type={"radio"}
            id={`esp-sim`}
            label={`Sim`}
            name={"concurso.especial"}
            value={1}
            checked={formik.values.concurso?.especial === 1 ? true : false}
            onChange={hundleClickRadio}
            onBlur={formik.handleBlur}
          />
        </Col>
      </Row>

      {formik.values.concurso?.especial !== 0 &&
        acessibilidades.map((item, index) => {
          return (
            <div key={index}>
              <Form.Check
                type={"checkbox"}
                id={index}
                label={item}
                onChange={(e) => {
                  addEspecial(e);
                }}
                checked={formik.values.concurso.acessibilidades.includes(item)}
                value={item}
                isInvalid={
                  formik.values.concurso.acessibilidades.length===0 &&
                  formik.values.concurso.ciente1 === 1 &&
                  formik.values.concurso.ciente2 === 1
                }
              />
            </div>
          );
        })}
    </div>
  );
};

export default Especial;
