import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import Menu from "../../../../components/Menu";
import Close from "../../../../components/Close";
import Steps from "../Steps";
import { useContext } from "react";
import { RegisterContext } from "../../../../context/RegisterContext";
import { cpf } from "cpf-cnpj-validator";
import Swal from "sweetalert2";
import Auth from "../../../../helpers/Auth";
import { useNavigate } from "react-router-dom";

const RegisterCPF = () => {
  const { usuario, setUsuario } = useContext(RegisterContext);
  const navigate = useNavigate();
  const { http, logout } = Auth();

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (!cpf.isValid(usuario.cpf.replace(/\D/g, ""))) {
        messageError("Digite um CPF válido!");
        return;
      }
      const resp = await http.post("verificar-cpf", { cpf: usuario.cpf });
      if (resp.status === 200) navigate("/register/form1");
    } catch (erro) {
      if (erro.response.status === 401) logout();
      else {
        messageError("CPF já cadastrado!");
        navigate("/login");
      }
    }
  }

  function handleChange(e) {
    const { value } = e.target;
    setUsuario({ ...usuario, cpf: value.replace(/\D/g, "") });
  }

  function messageError(message) {
    Swal.fire({
      icon: "error",
      title: message,
      confirmButtonColor: "#dc3545",
    });
  }

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <Menu />
      <Container className="p-3 justify-content-center align-items-center d-flex">
        <Card
          className="border-0 shadow-sm rounded-4 p-4"
          style={{
            backgroundColor: "#fff",
            maxWidth: "510px",
            minWidth: "380px",
          }}
        >
          <Form  className="h-100" onSubmit={handleSubmit} autoComplete="off">
            <Close />

            <Steps page={0} tamanho={6} />

            <div className="p-2">
              <Row>
                <span className="my-4 fw-bold fs-5">
                  Para iniciar seu cadastro, insira seu CPF no campo abaixo.
                </span>
                <Col md>
                  <Form.Group
                    className="w-100 m-0 align-content-center mb-3"
                    controlId={"cpf"}
                  >
                    <Form.Label
                      className=" mb-0 fw-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      CPF
                    </Form.Label>

                    <Form.Control
                      autoComplete="off"
                      onPaste={(e) => e.preventDefault()}
                      value={usuario.cpf}
                      className="rounded-4"
                      as={IMaskInput}
                      mask={"000.000.000-00"}
                      type="text"
                      name="cpf"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Col className="d-grid gap-2">
                <Button
                  style={{ fontSize: "1rem" }}
                  type="submit"
                  variant="primary"
                  className="rounded-3 me-2"
                >
                  Avançar
                </Button>
              </Col>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default RegisterCPF;
