import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Menu from "../../../../components/Menu";
import Close from "../../../../components/Close";
import Steps from "../Steps";
import { useContext } from "react";
import { RegisterContext } from "../../../../context/RegisterContext";
import Swal from "sweetalert2";
import Auth from "../../../../helpers/Auth";
import { useNavigate } from "react-router-dom";

const Form3 = () => {
  const { dados_usuarios, setDados_usuarios, db } = useContext(RegisterContext);
  const navigate = useNavigate();
  const { http, logout } = Auth();

  function handleSubmit(e) {
    e.preventDefault();
    console.log(dados_usuarios);
    navigate("/register/form4");
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setDados_usuarios({ ...dados_usuarios, [name]: parseInt(value) });
  }

  function messageError(message) {
    Swal.fire({
      icon: "error",
      text: message,
      confirmButtonColor: "#dc3545",
    });
  }

  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <Menu />
      <Container className="p-3 justify-content-center align-items-center d-flex">
        <Card
          className="border-0 shadow-sm rounded-4 p-4"
          style={{
            backgroundColor: "#fff",
            maxWidth: "510px",
            minWidth: "380px",
          }}
        >
          <Form
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
              }
            }}
            className="h-100"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Close />

            <Steps page={3} tamanho={6} />

            <div className="p-2">
              <div
                className=" mb-0 fw-bold text-break"
                style={{ fontSize: "1rem", width: "300px" }}
              >
                Considerando a possibilidade de alguma das etapas serem
                realizadas em um dia de sábado, informe aqui caso seja membro de
                grupo Adventista do Sétimo Dia
              </div>

              <div className="d-flex mt-4 align-items-center justify-content-center">
                <Form.Group
                  className=" fw-bold mb-4 d-flex align-items-center justify-content-center me-2"
                  controlId={"nao"}
                >
                  <Form.Check
                    checked={dados_usuarios.sabado === 0 ? true : false}
                    style={{ fontSize: "1rem" }}
                    label="Não"
                    inline
                    type="radio"
                    id={`0`}
                    className="me-1"
                    name="sabado"
                    onChange={handleChange}
                    value={0}
                  />
                </Form.Group>

                <Form.Group
                  className="fw-bold mb-4 d-flex align-items-center justify-content-center ms-2"
                  controlId={"sim"}
                >
                  <Form.Check
                    checked={dados_usuarios.sabado === 1 ? true : false}
                    style={{ fontSize: "1rem" }}
                    label="Sim"
                    inline
                    type="radio"
                    id={`1`}
                    className="me-1"
                    name="sabado"
                    onChange={handleChange}
                    value={1}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Col className="d-grid gap-2">
                <Button
                  type="button"
                  variant="primary"
                  className="rounded-3 me-2"
                  onClick={() => navigate("/register/form2")}
                  style={{ fontSize: "1rem" }}
                >
                  Voltar
                </Button>
              </Col>

              <Col className="d-grid gap-2">
                <Button
                  style={{ fontSize: "1rem" }}
                  type="submit"
                  variant="primary"
                  className="rounded-3 me-2"
                >
                  Avançar
                </Button>
              </Col>
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default Form3;
