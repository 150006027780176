import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Auth from "./helpers/Auth";
import AppRoutes from "./routes";
import EsqueciSenha from "./pages/Senha/Esqueci";
import VLibras from "@djpfs/react-vlibras";
import RedefinirSenha from "./pages/Senha/Redefinir";
import EmailAutenticacao from "./pages/Email";
import RegisterProvider from "./context/RegisterContext";
import RegisterCPF from "./pages/Register/components/CPF";
import Form1 from "./pages/Register/components/Form1";
import Form2 from "./pages/Register/components/Form2";
import Form3 from "./pages/Register/components/Form3";
import Form4 from "./pages/Register/components/Form4";
import Form5 from "./pages/Register/components/Form5";

function App() {
  const { getToken } = Auth();

  if (!getToken()) {
    return (
      <>
        <VLibras />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register/cpf" element={<RegisterProvider><RegisterCPF /></RegisterProvider>} />
          <Route path="/register/form1" element={<RegisterProvider><Form1/></RegisterProvider>} />
          <Route path="/register/form2" element={<RegisterProvider><Form2/></RegisterProvider>} />
          <Route path="/register/form3" element={<RegisterProvider><Form3/></RegisterProvider>} />
          <Route path="/register/form4" element={<RegisterProvider><Form4/></RegisterProvider>} />
          <Route path="/register/form5" element={<RegisterProvider><Form5/></RegisterProvider>} />
          <Route path="/senha" element={<EsqueciSenha />} />
          <Route path="/email" element={<EmailAutenticacao />} />
          <Route path="/redefinir/:token" element={<RedefinirSenha />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <VLibras />
      <AppRoutes />
    </>
  );
}

export default App;
