import { Formik } from "formik";
import { Alert, Button, Card, Col, Container, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Senha, ValidadeSenha } from "../../../helpers/Schema";
import Auth from "../../../helpers/Auth";
import { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import Swal from "sweetalert2";

const TrocarSenha = () => {
  const navigate = useNavigate();
  const { http, logout, user } = Auth();
  const [senha, setSenha] = useState(false);
  const [show, setShow] = useState(false);

  const styleBotao =
    window.innerWidth <= 768
      ? { width: "30px", background: "#fff", left: "93%" }
      : { width: "35px", background: "#fff", left: "95%" };

  const hundleSubmit = async (values) => {
    const submit = { ...values, id: user.users_id };

    await http
      .post("trocar-senha", submit)
      .then((response) => {
        navigate("/login");
      })
      .catch((error) => {
        if (error.response.status === 401) logout();
        else {
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonColor: "#dc3545",
          });
        }
        setSenha(true);
      });
  };

  return (
    <div style={{ background: "#fafafa" }}>
      <Container className="mt-5 justify-content-center align-items-center d-flex">
        <Card
          className="shadow-sm border-0 rounded-4 p-4"
          style={{ background: "#fff", width: "600px" }}
        >
          <div className="fs-3 fw-bold mb-3 text-center">Trocar de Senha</div>

          {senha && (
            <div className="text-center">
              <Alert key={"danger"} variant={"danger "}>
                <span className="text-danger">Senha inválida</span>
              </Alert>
            </div>
          )}

          <Formik
            initialValues={Senha}
            validationSchema={ValidadeSenha}
            onSubmit={(values) => hundleSubmit(values)}
          >
            {(formik) => (
              <Form
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                  }
                }}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Form.Group controlId="oldsenha" className="position-relative">
                  <p className="m-0 fw-bold" style={{ fontSize: "1.1rem" }}>
                    Digite sua senha atual
                  </p>

                  <Form.Control
                    type={show ? "text" : "password"}
                    placeholder=""
                    className="mb-3 rounded-4"
                    name="senha"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={formik.errors.senha && formik.touched.senha}
                  />

                  <Button
                    style={{ ...styleBotao, top: "68%" }}
                    className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <FaEyeSlash fill="#3b3b3b" />
                    ) : (
                      <FaEye fill="#3b3b3b" />
                    )}
                  </Button>
                </Form.Group>

                <Form.Group
                  controlId="newsenha"
                  className="mb-3 position-relative"
                >
                  <p className="m-0 fw-bold" style={{ fontSize: "1.1rem" }}>
                    Digite sua nova senha
                  </p>
                  <Form.Control
                    type={show ? "text" : "password"}
                    placeholder=""
                    className="rounded-4"
                    name="newSenha"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newSenha}
                    isInvalid={
                      formik.errors.newSenha && formik.touched.newSenha
                    }
                  />
                  <Button
                    style={{ ...styleBotao, top: "40%" }}
                    className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <FaEyeSlash fill="#3b3b3b" />
                    ) : (
                      <FaEye fill="#3b3b3b" />
                    )}
                  </Button>
                  <div className="mt-1" style={{ fontSize: "0.9rem" }}>
                    Digite 8 dígitos, ou mais, contendo pelo menos uma letra
                    maiúscula, uma letra minúscula e um número
                  </div>
                </Form.Group>

                <Form.Group
                  controlId="newsenhaconfirm"
                  className="mb-3 position-relative"
                >
                  <p className="m-0 fw-bold" style={{ fontSize: "1.1rem" }}>
                    Confirme sua nova senha
                  </p>

                  <Form.Control
                    type={show ? "text" : "password"}
                    placeholder=""
                    className="rounded-4"
                    name="newSenhaConfirm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newSenhaConfirm}
                    isInvalid={
                      formik.errors.newSenhaConfirm &&
                      formik.touched.newSenhaConfirm
                    }
                  />
                  <Button
                    style={{ ...styleBotao, top: "68%" }}
                    className="position-absolute end-0 translate-middle border-0 rounded-pill p-0"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <FaEyeSlash fill="#3b3b3b" />
                    ) : (
                      <FaEye fill="#3b3b3b" />
                    )}
                  </Button>
                </Form.Group>

                <div className="d-flex justify-content-center align-items-center">
                  <Col>
                    <Link to={"/home"} className="d-grid gap-2">
                      <Button
                        type="submit"
                        variant="secondary"
                        className="rounded-3 me-2"
                      >
                        Cancelar
                      </Button>
                    </Link>
                  </Col>
                  <Col className="d-grid gap-2">
                    <Button type="submit" className="rounded-3 ms-2">
                      Trocar
                    </Button>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </div>
  );
};

export default TrocarSenha;
