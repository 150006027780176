import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import Menu from "../components/Menu";
import Inscricao from "../pages/Incricao";
import MeusConcursos from "../pages/MeusConcursos";
import Atualizar from "../pages/Atualizar";
import TrocarSenha from "../pages/Senha/Trocar";
import { useEffect } from "react";
import Auth from "../helpers/Auth";

const AppRouter = () => {

    const navigate = useNavigate()
    const { getToken } = Auth();
    useEffect(() => {
      if (!getToken) {
        return navigate("/login");
      }
    }, []);

    return ( 

        <div className="h-100" style={{ background:'#fafafa' }}>
            <div className="vw-100 vh-100" >
                <Menu/>
 
                <Routes>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/atualizar/:id" element={<Atualizar/>} />
                    <Route path="/inscricao" element={<Inscricao/>}/>
                    <Route path="/meus-concursos-inscricoes" element={<MeusConcursos/>} />
                    <Route path="/trocar-senha" element={<TrocarSenha/>} />
                    <Route path="*" element={<Navigate to={"/home"} />}/>
                </Routes>

            </div>
        </div>

     );
}
 
export default AppRouter;