import { useState } from "react";
import Auth from "../../helpers/Auth";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { IMaskInput } from "react-imask";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CespecSVG from "../../assets/Cespec";
import Swal from "sweetalert2";

import { Card, Container, Form, Button, Alert, Modal } from "react-bootstrap";
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          alt="aviso"
          src="https://cespec.com.br/wp-content/uploads/2024/10/aviso-cespec.jpg"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal.Body>
    </Modal>
  );
}

const Login = () => {
  const { http, setToken, logout } = Auth();
  const [message, setMessage] = useState("");
  const [load, setLoad] = useState(false);
  const [incorreto, setIncorreto] = useState(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const navigate = useNavigate();

  const [login, setLogin] = useState({
    cpf: "",
    senha: "",
  });

  const hundleLogin = (e) => {
    setLogin({ ...login, cpf: e.target.value });
  };

  const hundleSenha = (e) => {
    setLogin({ ...login, senha: e.target.value });
  };

  const hundleSubmit = async (e) => {
    setLoad(true);
    e.preventDefault();
    const submit = { ...login, cpf: login.cpf.replace(/\D/g, "") };
    try {
      await http.post("/login", submit).then((res) => {
        setToken(
          res.data.authorisation.user,
          res.data.authorisation.acess_token
        );
      });
    } catch (error) {
      const { message } = error.response.data;
      switch (message) {
        case "cpf ou senha pode estar incorreta":
          Swal.fire({
            icon: "error",
            text: "Seu CPF ou sua senha está incorreta!",
            confirmButtonText: "Ok",
          });
          break;
        case "Seu email ainda não foi verificado.":
          Swal.fire({
            icon: "error",
            text: "Seu email ainda não está autenticado!",
            confirmButtonText: "Autenticar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/email");
            }
          });
          break;
        default:
          Swal.fire({
            icon: "error",
            text: "Algum erro com o servidor aconteceu. Tente novamente!",
            confirmButtonText: "Ok",
          });
          break;
      }
    } finally {
      setLoad(false);
    }
  };
  // Seu email ainda não foi verificado.
  return (
    <div style={{ background: "#fafafa" }}>
      <div
        className="position-absolute top-50 start-50 translate-middle bg-white bg-opacity-75 w-100"
        style={{ zIndex: "1" }}
      >
        {load ? <Loading /> : null}
      </div>

      {/* <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}

      <Container>
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ minHeight: "100vh" }}
        >
          {/* <Alert variant="danger" style={{maxWidth: '900px'}}>
            <small>
              <p>Prezados(as) candidatos(as),</p>
              <p>
                Antes de iniciar o seu cadastro ou inscrição, recomendamos a
                leitura atenta do Edital 01/2024. Ele contém informações
                importantes sobre o processo seletivo. Além disso, em nosso
                site, disponibilizamos um guia passo a passo para auxiliar os
                candidatos que ainda não são cadastrados em nosso sistema.
              </p>
              <p>
                Ao realizar o cadastro, é fundamental que o candidato(a)
                <strong> verifique se o seu endereço de e-mail está válido e correto.</strong>
                Isso garantirá que não haja problemas no recebimento da
                confirmação de inscrição. <strong>Verifique seu e-mail e as pastas de
                lixo eletrônico e spam.</strong> 
              </p>
            </small>
          </Alert> */}
          <Card
            className="shadow rounded bg-body-tertiary border-0 py-4"
            // style={{ height: "40vh" }}
          >
            <Form
              className="d-flex flex-column justify-content-around h-100"
              onSubmit={hundleSubmit}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                }
              }}
            >
              {/* <img src="cespec.png" className="my-3 mx-5" alt="logo" /> */}

              <CespecSVG
                className="mx-5 mb-3"
                width={300}
                height={(300 / 353) * 145}
                viewBox="0 0 300 80"
              />

              <span className="text-center mb-2">
                Login - Página de inscrição
              </span>

              <div>
                <div className="text-danger d-flex justify-content-center mb-3">
                  {message}
                </div>

                <Form.Group className="mb-3 mx-5" controlId="formBasicCPF">
                  <Form.Control
                    as={IMaskInput}
                    mask={"000.000.000-00"}
                    required
                    type="text"
                    placeholder="CPF"
                    onChange={hundleLogin}
                    className="rounded-pill"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3 mx-5 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    required
                    type={show ? "text" : "password"}
                    placeholder="Senha"
                    onChange={hundleSenha}
                    className="rounded-pill"
                  />
                  <Button
                    style={{ width: "10%", background: "#fff", left: "93%" }}
                    className="position-absolute top-50 translate-middle border-0 rounded-pill p-0"
                    onClick={() => setShow(!show)}
                  >
                    {show ? (
                      <FaEyeSlash fill="#3b3b3b" />
                    ) : (
                      <FaEye fill="#3b3b3b" />
                    )}
                  </Button>
                </Form.Group>

                <div className="d-grid gap-2 mx-5 mb-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="rounded-pill"
                  >
                    Entrar
                  </Button>
                </div>

                <div className="text-center mb-1">
                  Não possui uma conta?{" "}
                  <Link className="text-decoration-none" to={"/register/cpf"}>
                    Cadastre-se
                  </Link>
                </div>

                <div className="text-center">
                  <Link className="text-decoration-none" to={"/senha"}>
                    Esqueceu sua senha?
                  </Link>
                </div>
              </div>
            </Form>
          </Card>
          {/* <Alert variant="danger" style={{maxWidth: '900px'}}>
            <small>
              <p>
                Para esclarecer eventuais dúvidas, acessem a seção de “Dúvidas
                Frequentes” no topo do nosso site.
              </p>
            </small>
          </Alert> */}
        </div>
      </Container>
    </div>
  );
};

export default Login;
